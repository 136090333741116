import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Icons from "../../Icons";

export default function InputCom({
  label,
  type,
  name,
  placeholder,
  iconName,
  passIcon,
  inputHandler,
  value,
  forgotPassword,
  parentClass,
  labelClass,
  labalClass,
  inputClass,
  fieldClass,
  onClick,
  disable,
  blurHandler,
  spanTag,
  inputBg,
  onInput,
  maxLength = 45,
  minLength = 0,
  direction,
  tabIndex,
  error,
  autoComplete="on",
  minDate='1900-01-01',
  maxDate='2099-09-13'
}) {
  const inputRef = useRef(null);
  // Entry Validation
  // for Min Length:
  const minLengthValidation = () => {
    const inputConfig = inputConfigs[inputRef?.current?.name]?.minLength;
    return inputConfig || minLength;
  };

  // for MaxLength
  const maxLengthValidation = () => {
    const inputConfig = inputConfigs[inputRef?.current?.name]?.maxLength;
    return inputConfig || maxLength;
  };

  // for Patterns
  const inputPatterns = () => {
    const inputConfig = inputConfigs[inputRef?.current?.name]?.pattern;
    return inputConfig || "";
  };
  return (
    <div className={`input-com ${parentClass}`}>
      <div className={`flex items-center justify-between mb-2.5 ${labelClass}`}>
        {label && (
          <label
            className={`job-label ${error && 'job-label-flex'} ${labalClass}`}
            htmlFor={name}
          >
            {label}
            {spanTag && spanTag == "*" ? (
              <span className="text-red-700 text-sm tracking-wide">
                {" "}
                {spanTag}
              </span>
            ) : (
              <span className="text-green-700 text-sm tracking-wide">
                {spanTag}
              </span>
            )}
            {/* displays error is any */}
            {error && (
              <span className="text-[12px] text-red-500 italic">{error}</span>
            )}
          </label>
        )}
        {forgotPassword && (
          <Link
            to="/forgot-password"
            className="text-[13.975px] leading-[20.9625px] text-[#019ef7] hover:text-[#009ef7]"
          >
            Forgot Password?
          </Link>
        )}
      </div>
      <div
        className={`input-wrapper w-full rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding text-base ${
          inputClass
            ? inputClass
            : "text-[#5e6278] dark:text-gray-100 bg-[#f5f8fa]  border"
        }`}
      >
        <>
          {type == 'date' ?
          <input
            placeholder={placeholder}
            value={value}
            onChange={inputHandler}
            className={`input-field placeholder:text-base text-dark-gray w-full h-full ${iconName && 'pr-6'} ${
              inputBg && inputBg} tracking-wide focus:ring-0 focus:outline-none ${fieldClass}`}
            type={type}
            id={name}
            name={name}
            onInput={onInput}
            min={minDate}
            max={maxDate}
            tabIndex={tabIndex}
            // pattern={inputPatterns()}
            ref={inputRef}
            readOnly={disable}
            onBlur={blurHandler}
            dir={direction}
            autoComplete={autoComplete}
          />
          :
          <input
            placeholder={placeholder}
            value={value}
            onChange={inputHandler}
            className={`input-field placeholder:text-base text-dark-gray w-full h-full ${iconName && 'pr-6'} ${
              inputBg && inputBg} tracking-wide focus:ring-0 focus:outline-none ${fieldClass}`}
            type={type}
            id={name}
            name={name}
            onInput={onInput}
            minLength={minLengthValidation()}
            maxLength={maxLengthValidation()}
            tabIndex={tabIndex}
            // pattern={inputPatterns()}
            ref={inputRef}
            readOnly={disable}
            onBlur={blurHandler}
            dir={direction}
            autoComplete={autoComplete}
          />
          }
        </>
        {iconName && (
          <div className="absolute right-6 bottom-3 z-10 flex gap-2">
            {iconName.split(" ").map((item, index) => (
              <Icons key={index} name={item} />
            ))}
          </div>
        )}
        {passIcon && (
          <div className="absolute right-6 bottom-3 z-10" onClick={onClick}>
            <Icons name={passIcon} />
          </div>
        )}
      </div>
    </div>
  );
}

const inputConfigs = {
  email: { minLength: 7, maxLength: 45 },
  first_name: { minLength: 3, maxLength: 25, pattern: "[a-zA-Z]+" },
  last_name: { minLength: 3, maxLength: 25, pattern: "[a-zA-Z]+" },
  address: { minLength: 5, maxLength: 49, pattern: "[a-zA-Z0-9]+" },
  password: { minLength: 8, maxLength: 15, pattern: ".{8,}" },
  state: { minLength: 3, maxLength: 25, pattern: "[a-zA-Z]+" },
  province: { minLength: 3, maxLength: 25, pattern: "[a-zA-Z]+" },
  city: { minLength: 3, maxLength: 25, pattern: "[a-zA-Z]+" },
  amount: { minLength: 1, maxLength: 9, pattern: "[0-9]+" },
  description: { minLength: 5, maxLength: 299 },
  title: { minLength: 5, maxLength: 149 },
  job_detail: { minLength: 4, maxLength: 1440 },
  cardNum: { minLength: 4, maxLength: 19 },
};

/* Numbers Only: <input type="text" pattern="[0-9]*" /> strictly numbers
Alphabets Only: <input type="text" pattern="[a-zA-Z]+" /> strictly alphabets
Alphanumeric: <input type="text" pattern="[a-zA-Z0-9]+" /> mix
Password: <input type="text" pattern=".{8,}" /> a minimum of 8 characters 
*/
