import React, { useState } from "react";
import Layout from "../Partials/Layout";
import RecentPastDueTable from "./RecentPastDueTable";
import CommonHead from "../UserHeader/CommonHead";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function RecentlyPastDue({
  imageServer,
  recentPastDue,
  commonHeadData,
}) {
  const { userDetails } = useSelector(
    (state) => state?.userDetails
  );

  const navigate = useNavigate()

  const [selectTab, setValue] = useState("today");
  const filterHandler = (value) => {
    setValue(value);
  };

  let accountType = userDetails?.account_type == "FULL"

  return (
    <Layout>
      {userDetails?.account_type == 'FULL' &&
        <CommonHead commonHeadData={commonHeadData} />
      }
      
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
            <div className="w-full mb-5 flex justify-between items-center gap-1">
                <CustomBreadcrumb 
                    title = 'Past Due Tasks'
                    breadcrumb={
                    [
                        { link: "/", title: "Home" },
                        { link: "/mytask", title: "My Tasks", active: true},
                    ]
                    }
                />
                {/* } */}

                {/* <div className='flex gap-4 justify-end'>
                    <>
                    <Link
                        to='/mytask?tab=pastdue'
                        className="px-4 h-10 flex justify-center items-center text-lg md:text-xl rounded-xl text-red-600"
                    >
                        Past Due
                    </Link>

                    {ActiveJobList?.data?.length > 0 && userDetails.account_type == 'FAMILY' &&
                        <button
                        type="button"
                        onClick={() => {
                            navigate("/familymarket");
                        }}
                        className="px-4 h-10 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                        >
                        More Task
                        </button>
                    }
                    </>
                </div> */}
            </div>

          <RecentPastDueTable recentPastDue={recentPastDue} Account={userDetails} imageServer={imageServer} />
        </div>
      </div>
    </Layout>
  );
}
