import React from "react";
import LoginLanding from "../components/AuthPages/Login/LoginLanding";

function LoginLandingPage() {
  return (
    <>
      <LoginLanding />
    </>
  );
}

export default LoginLandingPage;
