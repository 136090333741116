import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  default as logo,
  default as logo3,
} from "../../assets/images/wrenchboard-logo-text.png";
import DarkModeContext from "../Contexts/DarkModeContext";
import Icons from "../Helpers/Icons";

export default function Sidebar({
  sidebar,
  action,
  logoutModalHandler,
  myJobList,
}) {
  const darkMode = useContext(DarkModeContext);

  let { userDetails } = useSelector((state) => state.userDetails);
  //const jobLists =  getJobList(); // pass from upper - we need in a lot of places
  let { jobLists } = useSelector((state) => state.jobLists);
  const marketData = jobLists?.result_list;
  let noOfJobs = marketData?.length <= 0 ? "0" : marketData?.length;

  let walletExist = userDetails?.wallet_available_status == 'WALLET_AVAILABLE' || localStorage.getItem('wallet_available_status')== 'WALLET_AVAILABLE'

  useEffect(() => {
    const title = document.querySelectorAll(".menu-setting-items .heading");
    if (sidebar) {
      title.forEach((elm) => {
        elm.classList.add("active");
      });
    } else {
      title.forEach((elm) => {
        elm.classList.remove("active");
      });
    }
  }, [jobLists]);
  return (
    <div className="w-full h-full">
      {/* logo-area */}
      <div
        className={`w-full flex items-center transition-all duration-300 ease-in-out ${
          sidebar ? "justify-start gap-3  mb-14" : "justify-center"
        }`}
      >
        <div className={`sidebar-logo ${sidebar ? "enter" : ""}`}>
          {darkMode.theme === "light" ? (
            <img src={logo} alt="nft" />
          ) : (
            <img src={logo3} alt="nft" />
          )}
        </div>

        <span onClick={action}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="19"
            viewBox="0 0 25 19"
            fill="none"
          >
            <path
              d="M24.3544 8.45953L16.9855 0.271831C16.8283 0.0982522 16.6089 0 16.3763 0H11.4637C11.1411 0 10.848 0.189955 10.7153 0.484712C10.5843 0.781107 10.6384 1.12663 10.8545 1.36571L17.7306 9.00647L10.8545 16.6456C10.6384 16.8863 10.5827 17.2318 10.7153 17.5266C10.848 17.823 11.1411 18.0129 11.4637 18.0129H16.3763C16.6089 18.0129 16.8283 17.913 16.9855 17.7427L24.3544 9.55505C24.6344 9.24391 24.6344 8.76903 24.3544 8.45953Z"
              fill="url(#paint0_linear_159_67708)"
            />
            <path
              d="M13.7104 8.45953L6.34148 0.271831C6.18427 0.0982522 5.96484 0 5.73231 0H0.819691C0.497095 0 0.203976 0.189955 0.071335 0.484712C-0.0596682 0.781107 -0.00562942 1.12663 0.210526 1.36571L7.08656 9.00647L0.210526 16.6456C-0.00562942 16.8863 -0.0613058 17.2318 0.071335 17.5266C0.203976 17.823 0.497095 18.0129 0.819691 18.0129H5.73231C5.96484 18.0129 6.18427 17.913 6.34148 17.7427L13.7104 9.55505C13.9904 9.24391 13.9904 8.76903 13.7104 8.45953Z"
              fill="url(#paint1_linear_159_67708)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_159_67708"
                x1="10.644"
                y1="0"
                x2="28.9548"
                y2="13.8495"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F539F8" />
                <stop offset="0.416763" stopColor="#C342F9" />
                <stop offset="1" stopColor="#5356FB" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_159_67708"
                x1="0"
                y1="0"
                x2="18.3108"
                y2="13.8495"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F539F8" />
                <stop offset="0.416763" stopColor="#C342F9" />
                <stop offset="1" stopColor="#5356FB" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </div>
      {/* menu and settings item */}
      <div className="menu-setting-items mb-11">
        {/* menus item */}
        <div
          className={`menu-item transition-all duration-300 ease-in-out ${
            sidebar ? "mb-5" : "mb-2"
          }`}
        >
          <div className="heading mb-5">
            <h1 className={`${!sidebar && 'text-center'} title text-xl font-bold text-sky-blue`}>Menu</h1>
          </div>
          <div className="items">
            <ul className="flex flex-col space-y-6">
              {/* Using mini component reduces the bulk amount of html */}
              <ListItem
                title={userDetails?.account_type == "FULL" ? "Home" : "Home"}
                route="/"
                sidebar={sidebar}
                iconName="new-dashboard"
              />
              {userDetails && userDetails?.account_type !== "FAMILY" && (
                <ListItem
                  title="Market"
                  route="/market"
                  bubble={noOfJobs}
                  sidebar={sidebar}
                  iconName="new-market"
                />
              )}

              <ListItem
                title="My Task(s)"
                route="/mytask"
                sidebar={sidebar}
                iconName="new-mytask"
              />
            </ul>
          </div>
        </div>

        {/* menu and settings item */}
        {userDetails?.account_type !== "FAMILY" && (
          <div
            className={`menu-item transition-all duration-300 ease-in-out ${
              sidebar ? "mb-5" : "mb-2"
            }`}
          >
            <div className="heading mb-5">
              <h1 className={`${!sidebar && 'text-center'} title text-xl font-bold text-sky-blue`}>Family</h1>
            </div>
            <div className="items">
              <ul className="flex flex-col space-y-6">
                <ListItem
                  title="Family Corner"
                  route="/acc-family"
                  iconName="new-family"
                  sidebar={sidebar}
                />
              </ul>
            </div>
          </div>
        )}
        {(userDetails?.account_type !== "FAMILY" && walletExist) && (
          <>
            {!userDetails?.post_jobs ? (
              <div
                className={`menu-item transition-all duration-300 ease-in-out bg-[#f0f8ff] dark:bg-dark-white rounded-2xl p-3 ${
                  sidebar ? "mb-5" : "mb-2 rounded-none p-0"
                }`}
              >
                <div className="heading mb-5">
                  <h1 className="title text-xl font-bold text-sky-blue">
                    Job Post
                  </h1>
                </div>
                <div className="items">
                  <ul className="flex flex-col space-y-6">
                    <li className="item group">
                      <NavLink
                        to="/start-job"
                        className={`nav-item flex items-center ${
                          ((navData) => (navData.isActive ? "active" : ""),
                          sidebar
                            ? "justify-start space-x-3.5"
                            : "justify-center")
                        }`}
                      >
                        <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple dark:bg-dark-light-purple  rounded-full text-dark-gray dark:text-white dark:text-lighter-gray">
                          <Icons name="people-two" />
                        </span>
                        <span
                          className={`item-content group-hover:text-sky-blue text-[18px] transition-all duration-300 ease-in-out text-lighter-gray relative font-medium ${
                            sidebar ? "active flex-1" : "w-0"
                          }`}
                        >
                          Enable Job Post
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            ) : myJobList?.data?.result_list?.length ? (
              <div
                className={`menu-item transition-all duration-300 ease-in-out ${
                  sidebar ? "mb-5" : "mb-2"
                }`}
              >
                <div className="heading mb-5">
                  <h1 className={`${!sidebar && 'text-center'} title text-xl font-bold text-sky-blue`}>
                    Jobs
                  </h1>
                </div>
                <div className="items">
                  <ul className="flex flex-col space-y-6">
                    {[
                      {
                        name: "List",
                        path: "/myjobs",
                        iconName: "job-list",
                      },
                      // {
                      //   name: "Waiting",
                      //   path: "/pend-interest",
                      //   iconName: "pending-job",
                      // },
                      {
                        name: "Offers",
                        path: "/my-offers",
                        iconName: "pending-job",
                      },
                      {
                        name: "Active",
                        path: "/my-active-jobs",
                        iconName: "active-job",
                      },
                    ].map(({ name, path, iconName }, idx) => (
                      <ListItem
                        key={idx}
                        title={name}
                        route={path}
                        sidebar={sidebar}
                        iconName={iconName}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className={`menu-item transition-all duration-300 ease-in-out ${
                  sidebar ? "mb-5" : "mb-2"
                }`}
              >
                <div className="heading mb-5">
                  <h1 className="title text-xl font-bold text-sky-blue">
                    My Jobs
                  </h1>
                </div>
                <div className="items">
                  <ul className="flex flex-col space-y-6">
                    <ListItem
                      title="Add Job"
                      route="/myjobs"
                      iconName="people-two"
                      sidebar={sidebar}
                      popup={true}
                    />
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {/* signout area */}
      {sidebar ? (
        <button
          onClick={logoutModalHandler}
          type="button"
          className="signout-btn flex items-center space-x-1  p-2.5 h-[52px] bg-sky-blue transition duration-300 ease-in-out hover:bg-gray-900 rounded-full"
        >
          <span className="">
            <Icons name="new-logout" />
          </span>
          <span
            className={`signout-btn-content text-white text-xl font-bold ${
              sidebar ? "active" : ""
            }`}
          >
            {process.env.REACT_APP_LOGOUT_TEXT}
          </span>
        </button>
      ) : (
        <button
          onClick={logoutModalHandler}
          type="button"
          className="signout-btn w-full flex items-center justify-center"
        >
          <span className="p-[1px] w-[40px] h-[40px] border border-purple rounded-full">
            <Icons name="new-logout" />
          </span>
        </button>
      )}
    </div>
  );
}

const ListItem = ({ sidebar, route, title, bubble, iconName, popup }) => {
  return (
    <li className={`item group`}>
      <NavLink
        to={route}
        state={popup ? { popup: true } : { popup: false }}
        className={`nav-item flex items-center  ${
          ((navData) => (navData.isActive ? "active" : ""),
          sidebar ? "justify-start space-x-3.5" : "justify-center")
        }`}
      >
        <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple dark:bg-dark-light-purple  rounded-full text-dark-gray dark:text-white dark:text-lighter-gray">
          <Icons name={iconName} />
        </span>
        <span
          className={`item-content relative group-hover:text-purple text-[18px] transition-all duration-300 ease-in-out text-lighter-gray font-medium ${
            sidebar ? "active flex-1" : "hidden"
          }`}
        >
          {title && title}
          {bubble && (
            <span className="absolute left-24 -top-1 text-sm flex justify-center items-center w-5 h-5 primary-gradient rounded-full text-white">
              {bubble}
            </span>
          )}
        </span>
      </NavLink>
    </li>
  );
};
