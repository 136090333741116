import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usersService from '../../../services/UsersService';
import {updateUserDetails} from "../../../store/UserDetails";
import { useDispatch } from "react-redux";
import AuthLayout from "../AuthLayout";

function AppleRedirect() {
    const location = useLocation();
    const navigate = useNavigate();
    const userApi = new usersService();
    const dispatch = useDispatch()

    const queryParams = new URLSearchParams(location?.search);
    const codeResponse = queryParams.get("code");

    useEffect(()=>{
      if(!codeResponse){
        navigate('/login', {state: {error: true}})
        return
      }
      console.log(codeResponse);

      setTimeout(()=>{ // remove LATER
        navigate('/login', {state: {error: true}})
      },2000)
      
      /*
      POST /token HTTP/1.1
      Host: oauth2.googleapis.com
      Content-Type: application/x-www-form-urlencoded

      code=4/P7q7W91a-oMsCeLvIaQm6bTrgtp7&
      client_id=your_client_id&
      client_secret=your_client_secret&
      redirect_uri=https%3A//oauth2.example.com/code&
      grant_type=authorization_code
      */
      var reqData = {
        auth_type: "APPLE",
        code: codeResponse,
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
      };
    //   userApi
    //     .authStart(reqData)
    //     .then((res) => {
    //       if (res.status == 200  && res.data.internal_return >= 0 && res.data.member_id && res.data.uid && res.data.session) {
    //         localStorage.setItem("member_id", `${res.data.member_id}`);
    //         localStorage.setItem("uid", `${res.data.uid}`);
    //         localStorage.setItem("session_token", `${res.data.session}`);
    //         dispatch(updateUserDetails({...res.data}));
    //         navigate('/', {replace: true})
    //         return
    //       }
    //       navigate('/login', {state: {error: true}})
    //     })
    //     .catch((error) => {
    //       navigate('/login', {state: {error: true}})
    //       console.log(error);
    //     });
    },[])
  return (
    <AuthLayout>
    <div className='min-h-[70vh]'>Redirecting ... </div>
  </AuthLayout>
  )
}

export default AppleRedirect
