import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import KidIcon from '../../../assets/images/auth/children.png'
import ParentIcon from '../../../assets/images/auth/adult.png'
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";

import { useDispatch } from "react-redux";
// import { updateUserDetails } from "../../../store/UserDetails";

import GoogleDownload from '../../../assets/images/download/andriod.jpg'
import IOSDownload from '../../../assets/images/download/apple.jpg'


export default function LoginLanding() {
  const queryParams = new URLSearchParams(location?.search);
  // const sessionExpired = queryParams.get("sessionExpired");

  const dispatch = useDispatch();
  const { state } = useLocation();

  const navigate = useNavigate();
  const userApi = new usersService();


  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          {/* <div className="mb-5">
            <Link to="#">
              <img
                src={WrenchBoard}
                alt="wrenchboard"
                className="h-10 mx-auto"
              />
            </Link>
          </div> */}
          {/* <div className="content-wrapper login shadow-md w-10/12 mx-auto flex justify-center items-center xl:bg-white dark:bg-dark-white 2xl:w-[828px]  rounded-[0.475rem] sm:p-7 p-5"> */}
          <div className="flex place-content-center">
            <div className="w-10/12">
              <div className='grid grid-cols-1 sm:grid-cols-5 gap-3'>
                {/* APP DOWNLOAD STORE */}
                <div className="col-span-1 order-2 sm:col-span-2 sm:order-1 w-full">
                  <div className="relative w-full h-full flex sm:flex-col justify-center gap-4">
                    <div className="w-28 sm:w-36">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_APPLE_APP}
                      >
                        <img src={IOSDownload} className='w-full h-auto' alt='IOS Download' />
                      </a>
                    </div>
                    <div className="w-28 sm:w-36">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={process.env.REACT_APP_ANDROID_APP}
                      >
                        <img src={GoogleDownload} className='w-full h-auto' alt='IOS Download' />
                      </a>
                    </div>
                    <div className='hidden sm:block absolute top-0 bottom-0 right-0 w-1 rounded-full bg-[linear-gradient(180deg,_#94a3b8,_#0f172a,_#94a3b8)]'></div>
                  </div>
                </div>
                <div className="col-span-1 order-1 sm:col-span-3 sm:order-2 title-area flex flex-col gap-5 justify-center items-center relative text-center mb-5">
                  <h1 className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                    <span className='font-semibold text-[#4687ba] hover:text-[#009ef7] transition'>Log in</span> as a:
                  </h1>

                  <div className='w-full flex flex-col gap-4'>
                    <button name='full' onClick={()=>navigate('/login/parent', {state:{loginType:'full'}})} className='w-full mx-auto flex justify-between items-center p-4 md:p-8 rounded-lg bg-[#d5ebf8] text-[#009ef7] cursor-pointer'>
                      <div className='flex items-center gap-4'>
                        <img className='w-12 h-auto' src={ParentIcon} alt='login key' />
                        <p className='text-lg font-semibold'>Adult or Parent</p>
                      </div>
                      {/* <span>&gt;</span> */}
                      <i className="fa-solid fa-caret-right"></i>
                    </button>

                    <button name='family' onClick={()=>navigate('/login/kid', {state:{loginType:'family'}})} className='w-full mx-auto flex justify-between items-center p-4 md:p-8 rounded-lg bg-[#f0eef1] text-[#a94ef3] cursor-pointer'>
                      <div className='flex items-center gap-4'>
                        <img className='w-12 h-auto' src={KidIcon} alt='login key' />
                        <p className='text-lg font-semibold'>Kids or Teens</p>
                      </div>
                      {/* <span>&gt;</span> */}
                      <i className="fa-solid fa-caret-right"></i>
                    </button>
                  </div>

                  <div className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className="font-semibold text-[#4687ba] hover:text-[#009ef7] transition"
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}