import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import usersService from "../../services/UsersService";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import WalletBox from "./WalletBox";
import { tableReload } from '../../store/TableReloads';

const WalletRoutes = () => {
  const dispatch = useDispatch()

  const apiCall = new usersService();
  const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE
  const { walletTable } = useSelector((state) => state.tableReload);

  const [paymentHistory, setPaymentHistory] = useState({
    loading: true,
    data: [],
  });

  const [allCountries, setAllCountries] = useState({
    // STATE TO HOLD LIST OF COUNTRIES
    loading: true,
    data: [],
  });

  const getPaymentHistory = () => {
    apiCall
      .getPaymentHx()
      .then((res) => {
        if (res.data.internal_return < 0) {
          setPaymentHistory({ loading: false, data: [] });
        } else {
          setPaymentHistory({ loading: false, data: res.data?.result_list });
        }
      })
      .catch(() => {
        setPaymentHistory({ loading: false, data: [] });
      });
  };

  // FUNCTION TO GET COUNTRIES
  const getCountry = () => {
    apiCall
      .getSignupCountryData()
      .then((res) => {
        if (res?.data?.internal_return < 0) {
          setAllCountries((prev) => ({ loading: false, data: [] }));
          return;
        }
        setAllCountries((prev) => ({
          loading: false,
          data: res?.data?.result_list,
        }));
      })
      .catch((error) => {
        setAllCountries((prev) => ({ loading: false, data: [] }));
      });
  };

  useEffect(() => {
    getCountry();
    getPaymentHistory();
  }, [walletTable]);

  useEffect(()=>{
    if(!walletDetails.loading && walletDetails?.data?.length < 1){
      dispatch(tableReload({ type: "WALLETTABLE" })); // reloads wallet page
    }
  },[])

  return (
    <Layout>
        <WalletBox
          wallet={walletDetails}
          payment={paymentHistory}
          countries={allCountries.data}
        />
    </Layout>
  );
};

export default WalletRoutes;
