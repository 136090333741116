import React from 'react'
import { useNavigate } from 'react-router-dom'
import localImgLoad from '../../lib/localImgLoad'

const ForgetPwdResponse = ({title, message, type}) => {
  const navigate = useNavigate()
  return (
    <>
        <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
            <h1 className={`${type ? 'text-black' : 'text-red-500'}text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]`}>
            {title}
            </h1>
        </div>
        <div className="title-area w-[100px] h-[100px] mx-auto flex flex-col justify-center items-center relative text-center mb-7">
            <img className='w-full h-full' src={`${type ? localImgLoad('images/icons/success.svg') : localImgLoad('images/icons/error.svg')}`} alt='alert-banner' />
        </div>
        <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
            <p className={`${type ? 'text-sky-blue' : 'text-red-500'} font-semibold dark:text-white mb-3 leading-[27.3px] text-[18px]`}>
            {message}
            </p>
        </div>
        <div className="signin-area mb-3.5">
            <div className="flex justify-center items-center gap-2">
                <button
                type="button"
                onClick={() => navigate("/login")}
                className={`h-[48px] rounded-full mb-6 text-[15px] font-semibold text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
                >
                    <span>Home</span>
                </button>
            </div>
        </div>
    </>
  )
}

export default ForgetPwdResponse