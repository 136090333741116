import React, { useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import PaginatedList from "../Pagination/PaginatedList";

import { PriceFormatter } from "../Helpers/PriceFormatter";

import familyImage from '../../assets/images/no-family-side.png'
import localImgLoad from "../../lib/localImgLoad";

export default function OffersInterestTable({offerInterestList, className}) {

    const navigate = useNavigate();
    let { pathname } = useLocation();
    
    const filterCategories = ["All Categories", "Explore", "Featured"];
    const [selectedCategory, setCategory] = useState(filterCategories[0]);

    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem =
        Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
    const currentOfferInterestList = offerInterestList?.data?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePagination = (e) => {
        handlePagingFunc(e, setCurrentPage);
    };

    let imgServer = offerInterestList?.imgServer // FOR RENDERING IMAGE FROM SERVER
    return (
        <div
            className={`update-table w-full my-8 p-8 bg-white dark:bg-dark-white rounded-2xl section-shadow min-h-[520px] ${
                className || ""
            }`}
        >
            
            {offerInterestList?.loading ? (
                    <div className="min-h-[520px] w-full flex flex-col justify-center items-center">
                        <LoadingSpinner size="16" color="sky-blue" />
                    </div>
                )
                :
                offerInterestList?.data?.length > 0 ?
                    (
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 relative">
                            <thead className="sticky top-0">
                            {/* <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-bottom ">
                                <th className="py-4">Name</th>
                                <th className="py-4 text-center">Last Login</th>
                                <th className="py-4 text-center">No of Tasks</th>
                                <th className="py-4 text-right"></th>
                            </tr> */}
                            </thead>
                            <tbody className="h-full">
                            {currentOfferInterestList?.map((item, index) => {
                                // let image = item.banner ? item.banner : 'default.jpg'
                                const image = localStorage.getItem("session_token")
                                ? `${imgServer}${localStorage.getItem("session_token")}/job/${
                                    item.job_uid
                                  }`
                                : "";
                                return (
                                <tr key={index} className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                                    <td className=" py-4">
                                        <div className="flex space-x-2 items-center">
                                            <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] p-2 bg-alice-blue rounded-full overflow-hidden flex justify-center items-center">
                                                <img
                                                    src={`${image}`}
                                                    alt="data"
                                                    className="w-full h-full rounded-full"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                                                    {item?.title}
                                                </h1>
                                                <span className="text-sm text-thin-light-gray">{item?.expire}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center py-4 px-2">
                                        <div className="flex space-x-1 items-center justify-center">
                                            <p className="font-bold text-x text-dark-gray dark:text-white">{item?.client_name}</p>
                                        </div>
                                    </td>
                                    <td className="text-center py-4 px-2">
                                        <div className="flex space-x-1 items-center justify-center">
                                            {/* <span className="font-bold text-x text-dark-gray dark:text-white">{formatNumber(item?.price * 0.01)}</span> */}
                                            <span className="font-bold text-x text-dark-gray dark:text-white">{PriceFormatter(item?.price * 0.01,item?.currency_code,item?.currency)}</span>
                                        </div>
                                    </td>
            
                                    <td className="text-right py-4">
                                        <button
                                            onClick={() => {
                                                navigate("/manage-offer", {
                                                    state: { ...item, pathname, jobImage:image },
                                                });
                                            }}
                                            type="button"
                                            className='w-12 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white'
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 11 20"
                                                id="Arrow"
                                                className="w-[0.7rem]"
                                                >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M.366 19.708c.405.39 1.06.39 1.464 0l8.563-8.264a1.95 1.95 0 0 0 0-2.827L1.768.292A1.063 1.063 0 0 0 .314.282a.976.976 0 0 0-.011 1.425l7.894 7.617a.975.975 0 0 1 0 1.414L.366 18.295a.974.974 0 0 0 0 1.413"
                                                    // fill=""
                                                    className="color000000 svgShape fill-[#fff]"
                                                ></path>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                                );
                            })
                            }
                            </tbody>
                        </table>
                    )
                    :
                    (
                        <div className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                            <p className="p-2">No list avaliable.</p>
                        </div>
                    )
            }
        
            {/* PAGINATION BUTTON */}
            <PaginatedList
                onClick={handlePagination}
                prev={currentPage == 0 ? true : false}
                next={
                    currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
                    offerInterestList?.data?.length
                        ? true
                        : false
                }
                data={offerInterestList?.data}
                start={indexOfFirstItem}
                stop={indexOfLastItem}
            />
            {/* END OF PAGINATION BUTTON */}
        </div>
    );
}




// Function to format number to two(2) decimal places
// function formatNumber(number) {
// // Convert the number to a string
// let numStr = String(number);

// // Split the string into integer and decimal parts
// let parts = numStr.split('.');
// let integerPart = parts[0];
// let decimalPart = parts[1] || '';

// // Add thousands separators to the integer part
// let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// // Truncate or pad the decimal part to two decimal points
// let formattedDecimal = decimalPart.slice(0, 2).padEnd(2, '0');

// // Combine the formatted integer and decimal parts
// let formattedNumber = formattedInteger + '.' + formattedDecimal;

// return formattedNumber;
// }