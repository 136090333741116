import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";
import debounce from "../../../hooks/debounce";
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout2";
import LoadingSpinner from '../../../components/Spinners/LoadingSpinner'
import { updateUserDetails } from "../../../store/UserDetails";

export default function VerifyLink() {

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgError, setMsgError] = useState("");
  const [linkLoader, setLinkLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [linkSuccess, setLinkSuccess] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const token = queryParams.get("vlnk");
  const userApi = new usersService();

  // email
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  // password
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // if verification is okay. set a complete signup form
  const completeSignup = async () => {
    try {
      if (email !== "" && password !== "") {
        setLinkLoader(true);
        var postData = {
          username: email,
          password: password,
          verify_link: token,
        };
        const res = await userApi?.CompleteSignUp(postData);

        if (res.status === 200) {
          const { data } = res;
          if (
            data?.status > 0 &&
            data?.internal_return == 100 &&
            data?.session != ""
          ) {
            localStorage.setItem("email", `${data?.email}`);
            localStorage.setItem("member_id", `${data?.member_id}`);
            localStorage.setItem("uid", `${data?.uid}`);
            localStorage.setItem("session_token", `${data?.session}`);
            localStorage.setItem("wallet_available_status", `${data?.wallet_available_status}`);
            dispatch(updateUserDetails({ ...data }));
            setTimeout(() => {
              navigate("/", { replace: true });
              setLinkLoader(false);
            }, 2000);
          } else {
            setLinkLoader(false);
            setMsgError("Invalid Link or Password Combination");
          }
        } else {
          setLinkLoader(false);
          setLinkSuccess(false);
          setMsgError("An error occurred");
        }
      } else {
        setMsgError("Please fill in fields");
      }
    } catch (error) {
      setLinkLoader(false);
      setLinkSuccess(false);
      throw new Error(error);
    } finally {
      setTimeout(() => {
        setMsgError(null);
      }, process.env.REACT_APP_SIGNUP_ERROR_TIMEOUT);
    }
  };

  // for verifying the incoming verification link and render the correct component
  const verifyEmail = useCallback(async (code) => {
    try {
      const verifyRes = await userApi.verifyEmail(code);
      if (verifyRes.status === 200) {
        let { data } = verifyRes;
        console.log("TESTING VERIFY", data);
        if (
          data &&
          data.internal_return >= 0 &&
          data.status == 0 &&
          data.pending_id != "" &&
          data.pending_uid != "" &&
          data.username != "" &&
          data.status_text === "Link Verified"
        ) {
          setEmail(data.username);
          setPageLoader(false);
        } else {
          setPageLoader(false);
          setLinkSuccess(false);
        }
      }
    } catch (error) {
      setPageLoader(false);
      setLinkSuccess(false);
      throw new Error(error);
    }
  }, []);

  // delay verify requests by 10000ms
  const debouncedEmail = debounce(verifyEmail, 1000);

  useEffect(() => {
    debouncedEmail(token);
  }, []);

  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        {pageLoader ? (
          <div className='flex flex-col justify-center items-center gap-4'>
            <img src={WrenchBoard} alt="wrenchboard" className="h-10 mx-auto" />
            <div className='flex flex-col justify-center items-center'>
              <LoadingSpinner height='h-40' size='8' />
              <p>Loading...</p>
              <p>please do not refresh</p>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex place-content-center">
              <div className="w-10/12">
                <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                  <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                    {linkSuccess
                      ? "Sign In to WrenchBoard"
                      : "Invalid verification link"}
                  </h1>
                </div>
                {/* If the verification was a success */}
                {linkSuccess ? (
                  <SuccessfulComponent
                    email={email}
                    password={password}
                    handleEmail={handleEmail}
                    handlePassword={handlePassword}
                    onSubmit={completeSignup}
                    msgErr={msgError}
                    loader={linkLoader}
                  />
                ) : (
                  <ErrorComponent onClick={() => navigate("/login")} />
                )}
              </div>
            </div>
          </div>
        )}
      </AuthLayout>
    </>
  );
}

const SuccessfulComponent = ({
  onSubmit,
  password,
  handlePassword,
  email,
  handleEmail,
  msgErr,
  loader,
}) => (
  <div className="input-area">
    {/* INPUT */}
    <div className="mb-5">
      <InputCom
        fieldClass="px-6"
        value={email}
        inputHandler={handleEmail}
        placeholder="support@mermsemr.com"
        label="Email"
        name="email"
        type="email"
        iconName="message"
        autoComplete="off"
      />
    </div>
    <div className="mb-5">
      <InputCom
        fieldClass="px-6"
        value={password}
        inputHandler={handlePassword}
        placeholder="● ● ● ● ● ●"
        label="Password"
        name="password"
        type="password"
        iconName="password"
        autoComplete="off"
      />
    </div>
    {msgErr && (
      <div className="relative p-4 text-[#912741] bg-[#fcd9e2] border-[#fbc6d3] mb-4 rounded-[0.475rem] text-md font-light leading-[19.5px] text-[13px]">
        {msgErr}
      </div>
    )}
    <div className="signin-area mb-3.5">
      <button
        onClick={onSubmit}
        type="button"
        className={`btn-login  rounded-[0.475rem] mb-6 text-xl text-white flex justify-center bg-[#4687ba] hover:bg-[#009ef7] transition-all duration-300 items-center text-[15px]`}
      >
        {loader ? (
          <div className="signup btn-loader"></div>
        ) : (
          <span>Continue</span>
        )}
      </button>
    </div>
  </div>
);

const ErrorComponent = ({ onClick }) => (
  <div className="input-area">
    <div className="my-5">
      <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
        This error occurs because you have already verified this link or the
        link has expired. Try login or reset password. If none worked, try to
        create the account from the start.
      </p>
    </div>

    <div className="signin-area flex justify-center mb-3.5">
      <button
        onClick={onClick}
        type="button"
        className={`rounded-[0.475rem] mb-6 text-[15px] font-semibold text-[#009ef7] hover:text-white flex justify-center bg-[#f1faff] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
      >
        <span>Return Home</span>
      </button>
    </div>
  </div>
);
