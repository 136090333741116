import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import usersService from '../../../services/UsersService';
import { updateUserDetails } from "../../../store/UserDetails";
import AuthLayout from "../AuthLayout";

function FbookRedirect() {
    const location = useLocation();
    const navigate = useNavigate();
    const userApi = new usersService();
    const dispatch = useDispatch()

    const queryParams = new URLSearchParams(location?.search);
    const codeResponse = queryParams.get("code");

    useEffect(()=>{
      if(!codeResponse){
        navigate('/login', {state: {error: true}})
        return
      }
      console.log(codeResponse);
      
      /*
      https://developers.facebook.com/docs/facebook-login/guides/advanced/manual-flow/#exchangecode
      Step 1. Get access token by code

      GET https://graph.facebook.com/v17.0/oauth/access_token?
        client_id={app-id}
        &redirect_uri={redirect-uri}
        &client_secret={app-secret}
        &code={code-parameter}

      https://developers.facebook.com/docs/facebook-login/guides/access-tokens/get-long-lived
      Step 2. Get long-lived token by access token
      
      curl -i -X GET "https://graph.facebook.com/{graph-api-version}/oauth/access_token?  
      grant_type=fb_exchange_token&          
      client_id={app-id}&
      client_secret={app-secret}&
      fb_exchange_token={your-access-token}" 
      */

      // process.env.REACT_APP_FACEBOOK_CLIENT_ID
      // process.env.REACT_APP_FACEBOOK_CLIENT_SCOPE
      
      var reqData = {
        auth_type: "FACEBOOK",
        code: codeResponse,
        redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
      };
      userApi
        .authStart(reqData)
        .then((res) => {
          if (res.status == 200  && res.data.internal_return >= 0 && res.data.member_id && res.data.uid && res.data.session) {
            localStorage.setItem("member_id", `${res.data.member_id}`);
            localStorage.setItem("uid", `${res.data.uid}`);
            localStorage.setItem("session_token", `${res.data.session}`);
            dispatch(updateUserDetails({...res.data}));
            navigate('/', {replace: true})
            return
          }
          navigate('/login', {state: {error: true}})
        })
        .catch((error) => {
          navigate('/login', {state: {error: true}})
          console.log(error);
        });
    },[])
  return (
    <AuthLayout>
    <div className='min-h-[70vh]'>Redirecting ... </div>
  </AuthLayout>
  )
}

export default FbookRedirect
