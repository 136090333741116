import React, { Suspense, lazy, useState } from "react";
import { Link } from "react-router-dom";
import VideoElement from '../../components/VideoCom/VideoElement'
import OfferJobPopout from '../../components/jobPopout/OfferJobPopout'
import { PriceFormatter } from "../Helpers/PriceFormatter";
import CountDown from '../Helpers/CountDown'

const AccountDashboard = ({ className, bannerList, offersList, imageServer }) => {

  let [offerPopout, setOfferPopout] = useState({ show: false, data: {} }); // STATE TO HOLD THE VALUE OF THE ALERT DETAILS AND DETERMINE WHEN TO SHOW

  let offersListLength = offersList?.length > 2 ? 2 : offersList?.length

  // getting the upper three banners for the home layout
  const getUpperBanner = bannerList?.filter((value, idx) => idx <= 2 - offersListLength);
  const getLowerBanner = bannerList?.filter((value, idx) => !getUpperBanner?.map(item => item?.title)?.includes(value.title));

  let getImage = ({ banner_location, banner }) => {
    if (banner_location == "LOCAL") {
      return require(`../../assets/images/${banner}`);
    } else {
      return banner;
    }
  };

  return (
    <>
      <div
        className={`w-full min-h-[450px] flex flex-col justify-between items-center gap-4 rounded-2xl overflow-hidden ${
          className || ""
        }`}
      >

        {/* for normal banner section */}
        <div className="w-full grid xxs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center justify-center gap-2 md:gap-4">
          {/* OFFER LIST DISPLAY */}
          <>
            {(offersList && offersList?.length > 0) &&
              offersList.map((item, index) => {
                let thePrice = PriceFormatter(
                  item?.price * 0.01,
                  item?.currency_code,
                  item?.currency
                );
              
                let image = `${imageServer}${localStorage.getItem("session_token")}/job/${item.job_uid}`
                if(index < offersListLength){
                  return (
                    <div key={item.offer_uid}>
                        <NewOfferCard datas={item} image={image} price={thePrice} setOfferPopout={setOfferPopout} />
                    </div>
                  )
                }
            })}
          </>

          {getUpperBanner?.map((props, idx) => {
            let image = getImage(props);

            let { short_title, short_description, short_button_text, link_path, card_type, blog_id } =
              props;

            return (
              <div key={idx}>
                <TopBanner
                  btn={short_button_text}
                  image={image}
                  title={short_title}
                  desc={short_description}
                  link_path={card_type=='BLOG' ? `${link_path}?blog_id=${blog_id}` : link_path}
                />
              </div>
            );
          })}
        </div>


        {/* for flat banner section */}
        <div className="w-full grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 grid items-center justify-center gap-2 md:gap-4">    
          {/* OFFER LIST DISPLAY */}
          {/* <>
            {(offersList && offersList?.length > 0) &&
              offersList.map((item, index) => {
                let thePrice = PriceFormatter(
                  item?.price * 0.01,
                  item?.currency_code,
                  item?.currency
                );
              
                let image = `${imageServer}${localStorage.getItem("session_token")}/job/${item.job_uid}`
                if(index >= 3) {
                    return(
                    <div key={item.offer_uid}>
                        <NewOfferCardFlat datas={item} image={image} price={thePrice} setOfferPopout={setOfferPopout} />
                    </div>
                    )
                }
            })}
          </> */}
          {getLowerBanner?.map((props, idx) => {
            let image = getImage(props);

            let { short_title, short_description, short_button_text, link_path, card_type, blog_id } =
              props;

            return (
              <div key={idx}>
                <LowerBanner
                  btn={short_button_text}
                  image={image}
                  title={short_title}
                  desc={short_description}
                  link_path={link_path}
                  card_type={card_type}
                  blog_id={blog_id}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Offer Job Popout */}
      {offerPopout.show && (
        <OfferJobPopout
          details={offerPopout.data}
          onClose={() => {
            setOfferPopout({ show: false, data: {} });
          }}
          situation={offerPopout.show}
        />
      )}
      {/* End of Offer Job Popout */}
    </>
  );
};

export default AccountDashboard;

const TopBanner = ({ image, title = "", desc = "", btn, link_path, key }) => {
  return (
    <div className="flex flex-col shadow-md rounded-xl dark:border-[#5356fb29] overflow-hidden" key={key}>
      <Link to={link_path} className="h-[12rem] bg-white">
        <img
          src={image}
          alt="banner-img"
          loading="lazy"
          className="w-auto mx-auto h-full"
        />
      </Link>
      <div className="rounded-b-xl bg-white dark:bg-dark-white">
        <div className="border-b border-slate-300 px-2 py-1 h-[5.4rem] flex flex-col gap-2 dark:text-white">
          <Link to={link_path} className="font-bold text-lg line-clamp-1">
            {title}
          </Link>
          <Link to={link_path} className="text-sm">
            {desc}
          </Link>
        </div>
        <div className="flex justify-between w-full p-1 items-center">
          <Link to={link_path} className="text-slate-300 font-semibold text-sm">
            {btn}
          </Link>
          <button className="flex items-center justify-center gap-2">
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const NewOfferCard = ({ datas, hidden = false, price, setOfferPopout, image }) => {
  return (
    <div className="flex flex-col shadow-md bg-red-50 dark:bg-dark-white rounded-xl dark:border-[#5356fb29] overflow-hidden">
      <div className="h-[12rem] bg-transparent">
        {/* thumbnail image/video */}
        {datas.job_type == "MEDIA" ?
        <Suspense fallback={<p>Loading...</p>}>
          <VideoElement videoId={datas?.media_uid} />
        </Suspense>
        :
        <div
          className="thumbnail w-full h-full rounded-xl overflow-hidden px-4 pt-4"
          style={{
            // background: `url(${image}) center / contain no-repeat`,
          }}
        >
          {/* <img src={image} className='' /> */}
          <div
          className="thumbnail w-full h-full rounded-xl overflow-hidden"
          style={{
            background: `url(${image}) center / contain no-repeat`,
          }}
        ></div>
          {hidden && <div className="flex justify-center"></div>}
        </div>
        }
      </div>
      <div className="rounded-b-xl bg-transparent dark:bg-dark-transparent">
        <div className="border-b border-slate-300 px-2 py-1 h-[5.4rem] flex flex-col gap-2 dark:text-white">
          <h1 className="font-bold text-lg line-clamp-1 text-center">
            {datas?.title}
          </h1>
          <div className="card-buttons flex justify-center items-center space-x-2">
            <button
              type="button"
              onClick={() =>
                setOfferPopout({ show: true, data: { ...datas, image } })
              }
              className="btn-shine w-2/3 h-[40px] text-white rounded-full text-sm bg-pink flex justify-center items-center"
            >
              Start Now
            </button>
          </div>
        </div>
        <div className="flex justify-between w-full p-1 items-center">
          <div className="flex gap-1 items-center">
            <p className="text-[12px] text-red-500 tracking-wide">Expires</p>
            <p className="text-[12px] font-semibold tracking-wide text-dark-gray dark:text-white">
              <CountDown lastDate={datas.expire} />
            </p>
          </div>
          <button className="flex items-center justify-center gap-2">
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
            <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          </button>
        </div>
      </div>
    </div>
  );
};

const NewOfferCardFlat = ({ datas, hidden = false, price, setOfferPopout, image }) => {
  return (
    <div className="flex flex-col shadow-md bg-red-50 dark:bg-dark-white rounded-xl dark:border-[#5356fb29] overflow-hidden">
      <div className="w-full xxs:flex justify-between items-center border-b border-slate-300 p-2">
        <div className="min-h-[130px] sm:min-h-[100px] flex justify-between items-center">
          <div className="px-2 flex flex-col gap-2 dark:text-white">
            <h1 className="font-bold text-lg line-clamp-1 text-center">
              {datas?.title}
            </h1>
            <div className="card-buttons flex items-center space-x-2">
              <button
                type="button"
                onClick={() =>
                  setOfferPopout({ show: true, data: { ...datas, image } })
                }
                className="btn-shine w-28 h-[40px] text-white rounded-full text-sm bg-pink flex justify-center items-center"
              >
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div className='w-[150px] h-[100px]'>
          {datas.job_type == "MEDIA" ?
          <Suspense fallback={<p>Loading...</p>}>
            <VideoElement videoId={datas?.media_uid} />
          </Suspense>
          :
          <div
            className="thumbnail w-full h-full rounded-xl overflow-hidden px-4 pt-4"
            style={{
              background: `url(${image}) center / contain no-repeat`,
            }}
          >
            {hidden && <div className="flex justify-center"></div>}
          </div>
          }
        </div>
      </div>
      <div className="flex justify-between w-full p-1 items-center">
        <div className="flex gap-1 items-center">
          <p className="text-[12px] text-red-500 tracking-wide">Expires</p>
          <p className="text-[12px] font-semibold tracking-wide text-dark-gray dark:text-white">
            <CountDown lastDate={datas.expire} />
          </p>
        </div>
        <button className="flex items-center justify-center gap-2">
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
        </button>
      </div>
    </div>
  );
};

const LowerBanner = ({ image, title = "", desc = "", btn, link_path, card_type, blog_id, key }) => {
  const newLinkPath = card_type == 'BLOG' ? `${link_path}?blog_id=${blog_id}` : link_path

  return (
    <div
      key={key}
      className="flex flex-col bg-white shadow-md h-full rounded-xl dark:border-[#5356fb29] dark:bg-dark-white"
    >
      <div className="w-full xxs:flex justify-between items-center border-b border-slate-300 p-2">
        <div className="min-h-[130px] sm:min-h-[100px] flex justify-between items-center">
          <div className="px-2 flex flex-col gap-2 dark:text-white">
            <Link to={newLinkPath} className="text-lg font-bold">
              {title}
            </Link>
            <p to={newLinkPath} className="text-sm">
              {desc}
            </p>
          </div>
        </div>
        <Link to={newLinkPath} className="w-[150px] h-[100px]">
          <img
            src={image}
            alt="banner-img"
            loading="lazy"
            className="w-full h-full rounded-xl"
          />
        </Link>
      </div>
      <div className="flex justify-between w-full p-1 items-center">
        <Link to={newLinkPath} className="text-slate-300 font-semibold text-sm">
          {btn}
        </Link>
        <button className="flex items-center justify-center gap-2">
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
          <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
        </button>
      </div>
    </div>
  );
};

const BannerSection = ({ banners, variant }) => {
  const getImage = ({ banner_location, banner }) => {
    return banner_location === "LOCAL"
      ? require(`../../assets/images/${banner}`)
      : banner;
  };

  return (
    <>
      {banners?.map((props, idx) => {
        const { short_title, short_description, short_button_text, link_path } =
          props;
        const image = getImage(props);

        return (
          <div
            key={idx}
            className={`flex flex-col shadow-md rounded-xl ${
              variant === "top" ? "" : "bg-white"
            }`}
          >
            <div className={`${variant === "top" ? "rounded-t-xl" : ""}`}>
              <Link to={link_path}>
                <img
                  src={image}
                  alt="banner-img"
                  className="w-full h-full rounded-t-xl object-cover"
                />
              </Link>
            </div>
            <div
              className={`${
                variant === "top"
                  ? "rounded-b-xl bg-white"
                  : "border-b border-slate-300"
              }`}
            >
              <div className="border-b border-slate-300 px-2 py-1 h-[5.4rem] flex flex-col gap-2">
                <Link to={link_path} className="font-bold text-lg">
                  {short_title}
                </Link>
                <Link to={link_path} className="text-sm">
                  {short_description}
                </Link>
              </div>
              <div className="flex justify-between w-full p-1 items-center">
                <Link to={link_path} className="text-slate-300 font-semibold">
                  {short_button_text}
                </Link>
                <button className="flex items-center justify-center gap-2">
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                  <div className="w-[4px] h-[4px] bg-slate-400 rounded-full"></div>
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
