import React from "react";
import { Link } from "react-router-dom";

export default function LoginLayout({ slogan, children }) {
  return (
    <div className={`layout-wrapper login`}>
      <div className={`main-wrapper login-wrapper w-full h-screen overflow-y-auto p-2 sm:px-20 sm:py-5`}>
        <div className="w-full h-full">
          <div className="flex-1 flex justify-center items-center">
            {children && children}
          </div>
          <div className="flex-1 flex justify-center items-center px-10 pt-10">
            <div className="flex items-center">
            <a
                  href="https://www.wrenchboard.com/about-us"
                  className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
                <a
                  href="https://www.wrenchboard.com/service"
                  className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                  target="_blank"
                  rel="noreferrer"
                >
                  Services
                </a>
                <a
                  href="https://www.wrenchboard.com/contact"
                  className="text-[#a1a5b7] text-[15px] px-2 font-medium hover:text-[#009ef7]"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center px-10 py-1">
            <p className="text-black text-[15px] px-2 font-medium flex items-center gap-1">
              <span className="">&copy; {new Date().getFullYear()} -</span>
              <Link to="/" className="text-[#009ef7] ml-1">
                WrenchBoard
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
