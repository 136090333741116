import React, {useEffect, useState} from 'react'
import Image from '../../assets/images/taskbanners/default.jpg'

import usersService from '../../services/UsersService';
import { handlePagingFunc } from '../../components/Pagination/HandlePagination';
import PaginatedList from '../../components/Pagination/PaginatedList';

import LoadingSpinner from '../Spinners/LoadingSpinner';

import { currencySymbol } from '../Helpers/PriceFormatter';
import {NewDateTimeFormatter} from '../../lib/NewDateTimeFormatter'

function JobsCompleted() {

    const apiCall = new usersService()

    let [jobHistory, setJobHistory] = useState({ // FOR PURCHASE HISTORY
        loading: true,
        data: [],
        error: false
    })

    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem = Number(indexOfFirstItem)+Number(process.env.REACT_APP_ITEM_PER_PAGE);
    const currentReward = jobHistory?.data?.slice(indexOfFirstItem, indexOfLastItem);
  
    const handlePagination = (e) => {
      handlePagingFunc(e,setCurrentPage)
    }


    //FUNCTION TO GET FAMILY REWARD HISTORY
    const getJobCompletedHistory = ()=>{
        apiCall.getContractHx().then((res)=>{
            if(res.data.internal_return < 0){ // success but no data
                setJobHistory(prev => ({...prev, loading: false}))
                return
            }
            setJobHistory(prev => ({...prev, loading: false, data: res.data.result_list}))
        }).catch((error)=>{
            setJobHistory(prev => ({...prev, loading: false, error: true}))
        })
    }

    useEffect(()=>{
        getJobCompletedHistory()
      }, [])

  return (
    <div className='py-4 flex flex-col justify-between min-h-[500px]'>
    {jobHistory?.loading ? 
    <LoadingSpinner size='16' color='sky-blue' height='h-[500px]' />
    : jobHistory?.data?.length ?
    <table className="wallet-activity w-full table-auto border-collapse text-left">
        <thead className='w-full'>
            <tr className='text-slate-600 dark:text-white'>
            <th className="p-4">Title</th>
            <th className="p-4 text-right">Amount</th>
            {/* <th className="p-4">Date</th>
            <th className="p-4">Contract</th> */}
            </tr>
        </thead>
        <tbody>
            {currentReward?.map((item, index) => {
                let date = new Date(item?.delivery_date).toLocaleDateString()
                return (
                    <tr key={index} className='dark:text-white dark:bg-dark-white border-y dark:border-[#5356fb29] hover:bg-gray-50 dark:hover:bg-gray-50 dark:hover:text-black transition-all duration-300'>
                        <td className="p-4 w-full">
                            <div className='flex items-center gap-2'>
                                {/* <img src={item.icon} className='min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] rounded-full bg-slate-500' alt='Reward Logo' /> */}
                                <div className='flex flex-col'>
                                    <h1 className='text-lg font-bold line-clamp-1'>{item?.title}</h1>
                                    <p className='text-sm line-clamp-2'>{item?.description}</p>
                                    <p className='text-sm line-clamp-2'>{NewDateTimeFormatter(item?.delivery_date)}</p>
                                </div>
                            </div>
                        </td>
                        <td className="p-4 flex text-right justify-end items-center">{currencySymbol(item?.currency_code, item?.price*0.01)}</td>
                    </tr>
                )
            }
            )}
        </tbody>
    </table>
    :jobHistory?.error ?
    <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
        <span>Opps! an error occurred. Please try again!</span>
    </div>
    :
    <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
        <span>No Completed Job History Found!</span>
    </div>
    }

    {/* PAGINATION BUTTON */}
    <PaginatedList onClick={handlePagination} prev={currentPage == 0 ? true : false} next={currentPage+Number(process.env.REACT_APP_ITEM_PER_PAGE) >= jobHistory?.data?.length ? true : false} data={jobHistory?.data} start={indexOfFirstItem} stop={indexOfLastItem} />
    {/* END OF PAGINATION BUTTON */}
    </div>
  )
}

export default JobsCompleted