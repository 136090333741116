import React from 'react'
import Icons from '../Helpers/Icons'

export default function TabButton({ item='', iconName='', selectedTab='', setSelectedTab=()=>{} }) {
  return (
    <button
      className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
        selectedTab === item ? "text-purple" : " text-thin-light-gray"
      }`}
      value={item}
      name={item}
      onClick={() => setSelectedTab(item)}
    >
      <div>
        <Icons name={iconName} />
      </div>
      <div>
        <p className="text-18 tracking-wide">{item[0]?.toUpperCase() + item?.slice(1)}</p>
      </div>
    </button>
  )
}

