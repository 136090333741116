import React, { useEffect, useRef } from 'react'

  
export default function VideoElement({videoId}) {
  let videoRef = useRef(null)

  useEffect(()=>{
    if(videoRef.current){
        videoRef.current.pause()
        videoRef.current.removeAttribute('src')
        videoRef.current.load()
    }
  },[videoId])

  return (
    <video ref={videoRef} className='w-full h-full' controls>
      <source src={`${process.env.REACT_APP_MEDIA_LINK}/videos/${videoId}`} type='video/mp4'></source>
      Your browser does not support the video tag.
  </video>
  )
}
  