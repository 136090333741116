import React, { useState } from "react";
import Layout from "../Partials/Layout";
import MyJobTable from "./MyJobTable";
import CommonHead from "../UserHeader/CommonHead";
// import TopSellerTopBuyerSliderSection from "../Home/TopSellerTopBuyerSliderSection";
import MyOffersTable from "./MyOffersTable";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";

export default function MyTasks({
  MyActiveOffersList,
  ActiveJobList,
  commonHeadData,
}) {
  const { userDetails } = useSelector(
    (state) => state?.userDetails
  );

  const navigate = useNavigate()

  const [selectTab, setValue] = useState("today");
  const filterHandler = (value) => {
    setValue(value);
  };

  let accountType = userDetails?.account_type == "FULL"

  console.log("Active something >>", MyActiveOffersList)

  return (
    <Layout>
      {userDetails?.account_type == 'FULL' &&
        <CommonHead commonHeadData={commonHeadData} />
      }
      
      <div className="notification-page w-full mb-10">
        <div className="notification-wrapper w-full">
          {/* heading */}
          <div className="sm:flex justify-between items-center hidden">
            {/* <div className="w-full mb-5 sm:mb-0 flex justify-between items-center gap-1">
              {userDetails.account_type == 'FAMILY' &&
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                <span
                  className={`${selectTab === "today" ? "block" : "hidden"}`}
                >
                  My Tasks
                </span>
              </h1>
              }
              {ActiveJobList?.data?.length > 0 && userDetails.account_type == 'FAMILY' &&
                <button
                  type="button"
                  onClick={() => {
                    navigate("/familymarket");
                  }}
                  className="px-4 h-10 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                >
                  More Task
                </button>
              }
            </div> */}
            <div className="slider-btns flex space-x-4">
              <div
                onClick={() => filterHandler("today")}
                className="relative"
              ></div>
            </div>
          </div>
          {MyActiveOffersList && accountType &&
            MyActiveOffersList?.result_list?.length >= 0 && (
              <MyOffersTable
                MyActiveOffersList={MyActiveOffersList}
                className="mb-10"
              />
            )}
            <div className="w-full mb-5 flex justify-between items-center gap-1">
              {/* {userDetails.account_type == 'FULL'? 
                <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                  <span
                    className={`${selectTab === "today" ? "block" : "hidden"}`}
                  >
                    My Tasks
                  </span>
                </h1>
                : */}
                <CustomBreadcrumb 
                  title = 'My Tasks'
                  breadcrumb={
                    [
                      { link: "/", title: "Home" },
                      { link: "/mytask", title: "My Tasks", active: true},
                    ]
                  }
                />
              {/* } */}
              <div className='flex gap-4 justify-end'>
                <>
                  <Link
                    // onClick={() => {
                    //   navigate(`/mytask?tab=pastdue`);
                    // }}
                    to='/mytask?tab=pastdue'
                    className="px-4 h-10 flex justify-center items-center text-lg md:text-xl rounded-xl text-red-600"
                  >
                    Past Due
                  </Link>

                  {ActiveJobList?.data?.length > 0 && userDetails.account_type == 'FAMILY' &&
                    <button
                      type="button"
                      onClick={() => {
                        navigate("/familymarket");
                      }}
                      className="px-4 h-10 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                    >
                      More Task
                    </button>
                  }
                </>
              </div>

            </div>

          <MyJobTable ActiveJobList={ActiveJobList} Account={userDetails} imageServer={MyActiveOffersList.session_image_server} />
        </div>
      </div>
    </Layout>
  );
}
