import React, {useState} from 'react'

import PaginatedList from '../../Pagination/PaginatedList';
import { handlePagingFunc } from '../../Pagination/HandlePagination';

import { currencySymbol } from '../../Helpers/PriceFormatter';

function PurchasesTable({purchase}) {

    const [currentPage, setCurrentPage] = useState(0);
    const indexOfFirstItem = Number(currentPage);
    const indexOfLastItem = Number(indexOfFirstItem)+Number(process.env.REACT_APP_ITEM_PER_PAGE);
    const currentPurchase = purchase?.data?.slice(indexOfFirstItem, indexOfLastItem);
  
    const handlePagination = (e) => {
      handlePagingFunc(e,setCurrentPage)
    }

  return (
    <div className='py-4 flex flex-col justify-between min-h-[500px]'>
        {purchase.data.length ?
        <table className="wallet-activity w-full table-auto border-collapse text-left">
            <thead className='w-full'>
                <tr className='text-slate-600 dark:text-white'>
                <th className="p-4">Trx.</th>
                <th className="p-4 text-right">Amount</th>
                <th className="p-4 text-right">Fee</th>
                </tr>
            </thead>
            <tbody>
                {currentPurchase.map((item, index) => (
                <tr key={index} className='dark:text-white dark:bg-dark-white border-y dark:border-[#5356fb29] hover:bg-gray-50 dark:hover:bg-gray-50 dark:hover:text-black transition-all duration-300'>
                    <td className="p-4">{item.added_date}<br />
                    <b>{item.confirmation} </b>
                    </td>
                    <td className="p-4 text-right">{currencySymbol(item.currency, item.amount)}</td>
                    <td className="p-4 text-right">{currencySymbol(item.currency, item.fee)}</td>
                </tr>
                ))}
            </tbody>
        </table>
        :purchase.error ?
        <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
            <span>Opps! an error occurred. Please try again!</span>
        </div>
        :
        <div className="p-2 text-slate-500 flex flex-col grow justify-center items-center">
            <span>No Purchase History Found!</span>
        </div> 
        }

    {/* PAGINATION BUTTON */}
    <PaginatedList onClick={handlePagination} prev={currentPage == 0 ? true : false} next={currentPage+Number(process.env.REACT_APP_ITEM_PER_PAGE) >= purchase?.data?.length ? true : false} data={purchase?.data} start={indexOfFirstItem} stop={indexOfLastItem} />
    {/* END OF PAGINATION BUTTON */}
    </div>
  )
}

export default PurchasesTable