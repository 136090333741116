import React from "react";
import LearnMore from "../components/LearnMore/LearnMore";

export default function LearnMorePage() {
  return (
    <>
      <LearnMore />
    </>
  );
}
