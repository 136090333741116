import { Suspense, lazy } from "react";
import localImgLoad from "../../lib/localImgLoad";
import CountDown from "../Helpers/CountDown";
import { PriceFormatter } from "../Helpers/PriceFormatter";

const VideoElement = lazy(() => import("../VideoCom/VideoElement")); // LAZY IMPORTING VIDEO COMPONENT

export default function OfferCard({
  datas,
  hidden = false,
  setOfferPopout,
  image_server,
}) {
  let thePrice = PriceFormatter(
    datas?.price * 0.01,
    datas?.currency_code,
    datas?.currency
  );

  let image = `${image_server}${localStorage.getItem("session_token")}/job/${datas.job_uid}`

  return (
    <div className="card-style-one flex flex-col gap-3 justify-between w-full bg-white dark:bg-dark-white p-3 rounded-2xl">
      <div className="content">
        <div className="w-full h-40">
          {/* thumbnail image/video */}
          {datas.job_type == "MEDIA" ?
          <Suspense fallback={<p>Loading...</p>}>
            <VideoElement videoId={datas?.media_uid} />
          </Suspense>
          :
          <div
            className="thumbnail w-full h-full rounded-xl overflow-hidden px-4 pt-4"
            style={{
              background: `url(${image}) center / contain no-repeat`,
            }}
          >
            {hidden && <div className="flex justify-center"></div>}
          </div>
          }
        </div>
        {/* details */}
        <div className="details">
          <h1 className="text-xl font-bold text-dark-gray dark:text-white mb-2 capitalize line-clamp-1">
            {datas.title}
          </h1>

          <div className="w-full p-2 rounded-lg border border-[#E3E4FE] dark:border-[#a7a9b533] ">
            {/* <div className="grid grid-cols-1 gap-2">
              <div className="flex flex-col justify-between items-center border-r-2">
                <p className="text-sm text-thin-light-gray dark:text-white tracking-wide">
                  Task Code
                </p>
                <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                  {datas.contract}
                </p>
              </div>
              <div className="flex flex-col justify-between items-center">
                <p className="text-sm text-red-500 tracking-wide">Expires</p>
                <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                  <CountDown lastDate={datas.expire} />
                </p>
              </div>
            </div> */}

            {/* <hr className="my-2" /> */}

            <div className="grid grid-cols-2 gap-2">
              <div className="flex flex-col justify-between items-center border-r-2">
                <p className="text-sm text-thin-light-gray dark:text-white tracking-wide">
                  Reward
                </p>
                <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                  {thePrice}
                </p>
              </div>
              {/* <div className="flex flex-col justify-between items-center">
                <p className="text-sm text-thin-light-gray dark:text-white tracking-wide">
                  Timeline
                </p>
                <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                  {`${datas.timeline_days} day(s)`}
                </p>
              </div> */}
              <div className="flex flex-col justify-between items-center">
                <p className="text-sm text-red-500 tracking-wide">Expires</p>
                <p className="text-base font-bold tracking-wide   text-dark-gray dark:text-white">
                  <CountDown lastDate={datas.expire} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-buttons flex justify-end items-center space-x-2">
        <button
          type="button"
          onClick={() =>
            setOfferPopout({ show: true, data: { ...datas, thePrice, image } })
          }
          className="btn-shine w-[98px] h-[33px] text-white rounded-full text-sm bg-pink flex justify-center items-center"
        >
          Start Task
        </button>
      </div>
    </div>
  );
}
