import React, { useState, useEffect } from "react";
import MyTasks from "../components/MyTasks";
// import UsersService from "../services/UsersService";
import usersService from "../services/UsersService";

import { useSelector } from "react-redux";
import RecentlyPastDue from "../components/MyTasks/RecentlyPastDue";

export default function MyTaskPage() {
  const api = new usersService();
  const queryParams = new URLSearchParams(location?.search);
  const tab = queryParams.get("tab")?.toLowerCase();

  const { myTaskTable } = useSelector((state) => state.tableReload);

  let { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  const [MyActiveJobList, setMyActiveJobList] = useState({
    loading: true,
    status: false,
    data: [],
  });
  const [MyActiveOffersList, setMyActiveOffersList] = useState([]);

  const [recentPastDue, setRecentPastDue] = useState({loading: true, data: {}});

  const getMyActiveJobList = async () => {
    setMyActiveJobList({ loading: true, status: false, data: [] });
    try {
      const res = await api.getMyActiveTaskList();
      setMyActiveJobList({
        loading: false,
        status: true,
        data: res.data.result_list,
      });
    } catch (error) {
      setMyActiveJobList({ loading: false, status: false, data: [] });
      console.log("Error getting tasks");
    }
  };

  const getMyActiveOffersList = async () => {
    // setMyActiveOffersList({loading: true, status:false, data:[]});
    try {
      const res = await api.getOffersList();
      setMyActiveOffersList(res.data);
    } catch (error) {
      // setMyActiveOffersList({loading: false, status:false, data:[]});
      console.log("Error getting offers");
    }
  };

  const getRecentPastDue = async () => {
    try {
      const res = await api.getRecentPastDueList();
      setRecentPastDue({loading: false, data:res?.data});
    } catch (error) {
      setRecentPastDue({loading: false, data:{}});
      console.log("Error getting offers");
    }
  };

  useEffect(()=>{
    getRecentPastDue()
  },[])

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([getMyActiveJobList(), getMyActiveOffersList()]);
    };

    fetchData();
  }, [myTaskTable]);

  //debugger;
  return (
    <>
    {tab == 'pastdue' ?
      <RecentlyPastDue
        recentPastDue={recentPastDue}
        imageServer={recentPastDue?.data?.session_image_server}
        commonHeadData={commonHeadBanner?.result_list}
      />
    :
      <MyTasks
        ActiveJobList={MyActiveJobList}
        MyActiveOffersList={MyActiveOffersList}
        commonHeadData={commonHeadBanner?.result_list}
      />
    }
    </>
  );
}
