import {useEffect} from 'react'
import { useSelector,  useDispatch} from "react-redux";
import Layout from "../Partials/Layout";
import CommonHead from "../UserHeader/CommonHead";
import MainSection from "./MainSection";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import { tableReload } from '../../store/TableReloads';

export default function MarketPlace({ commonHeadData }) {

  const dispatch = useDispatch()

  let { jobLists } = useSelector((state) => state.jobLists);
  const marketData = jobLists?.result_list;
  const categories = jobLists?.categories;
  const image_server = jobLists?.session_image_server;
  console.log('Lenth', marketData?.length )

  useEffect(()=>{
    if(!jobLists.loading && (marketData?.length < 1 || !marketData)){
      dispatch(tableReload({ type: "MARKETTABLELIST" })); // reloads market page
    }
  },[])

  return (
    <>
      <Layout>
        <CommonHead commonHeadData={commonHeadData} />
        <div className="mb-5">
            <CustomBreadcrumb 
            title = {'Market'}
            breadcrumb={
                [
                { link: "/", title: "Home" },
                { link: "/market", title: "Market", active: true},
                ]
            }
            />
        </div>
        {jobLists.loading ?
        <div className="w-full flex justify-center items-center bg-white dark:bg-dark-white">
          <LoadingSpinner size='20' height='h-[40rem]' />
        </div>
        :
        <MainSection
          marketPlaceProduct={marketData}
          categories={categories}
          image_server={image_server}
          className="mb-10"
        />
        }
      </Layout>
    </>
  );
}
