import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usersService from "../../services/UsersService";
import Icons from "../Helpers/Icons";
import Layout from "../Partials/Layout";
import {
  ChangePasswordTab,
  FaqTab,
  LoginActivityTab,
  NotificationSettingTab,
  PaymentMathodsTab,
  PersonalInfoTab,
  PrivacyPolicyTab,
  RecentActivitiesTab,
  TermsConditionTab,
  Preferences,
} from "./Tabs";
import RecipientAccountTab from "./Tabs/RecipientAccountTab";

export default function Settings({ faq }) {
  const apiCall = new usersService();
  const { userDetails } = useSelector((state) => state?.userDetails);
  const [reloadCardList, setReloadCardList] = useState(false); // STATE TO DETERMINE WHEN CARD LIST RELOADS. EG: WHEN USER DELETES A CARD

  // Recipient Account
  const [recipientAccount, setRecipientAccount] = useState({
    state: false,
    loader: false,
    msg: "",
    data: null,
  });

  const getRecipientAccount = useCallback(async () => {
    setRecipientAccount((prev) => ({ loader: true }));

    let res;
    try {
      res = await apiCall.getRecipient();
      res = res.data;

      if (res?.internal_return < 0) {
        setRecipientAccount((prev) => ({
          loader: false,
          msg: "Sorry, something went wrong",
        }));
        setTimeout(() => setRecipientAccount((prev) => prev), 5000);
        return;
      }

      return setTimeout(() => {
        setRecipientAccount((prev) => ({ loader: false, data: res }));
      });
    } catch (error) {
      setRecipientAccount((prev) => ({
        loader: false,
        msg: "Sorry, an error occurred",
      }));
      setTimeout(() => setRecipientAccount((prev) => prev), 5000);
      return;
    }
  }, [apiCall]);

  useEffect(() => {
    getRecipientAccount();
  }, [reloadCardList]);

  // Tabs Handling
  const tabs = [
    {
      id: 1,
      name: "personal",
      title: "Edit Profile",
      iconName: "people-hover",
    },
    {
      id: 2,
      name: "payment",
      title: "Payment Cards",
      iconName: "bank-card",
    },
    {
      id: 3,
      name: "recipients_account",
      title: "Recipients Account",
      iconName: "bank-card",
    },
    {
      id: 4,
      name: "notification",
      title: "Notification Setting",
      iconName: "notification-setting",
    },
    {
      id: 5,
      name: "preferences",
      title: "Preferences",
      iconName: "login-activity",
    },
    {
      id: 6,
      name: "recent_activities",
      title: "Recent Activities",
      iconName: "login-activity",
    },
    {
      id: 7,
      name: "password",
      title: "Change Password",
      iconName: "password-hover",
    },
    {
      id: 8,
      name: "faq",
      title: "FAQ",
      iconName: "block-question",
    },
    {
      id: 9,
      name: "privacy",
      title: "Privacy Policy",
      iconName: "page-right",
    },
    {
      id: 10,
      name: "terms",
      title: "Terms and Conditions",
      iconName: "page-right",
    },
  ];

  const [tab, setTab] = useState(() => {
    // Retrieve the active tab from local storage or use the default tab
    return localStorage.getItem("activeTab") || tabs[0].name;
  });

  const tabHandler = (value) => {
    setTab(value);
  };

  // Update local storage when the tab changes
  useEffect(() => {
    localStorage.setItem("activeTab", tab);
  }, [tab]);

  const tabComponents = {
    personal: (
      <div className="tab-item">
        <PersonalInfoTab />
      </div>
    ),
    payment: (
      <div className="tab-item">
        <PaymentMathodsTab />
      </div>
    ),
    recipients_account: (
      <div className="tab-item">
        <RecipientAccountTab
          recipientAccount={recipientAccount}
          setRecipientAccount={setRecipientAccount}
          setReloadCardList={setReloadCardList}
        />
      </div>
    ),
    notification: (
      <div className="tab-item">
        <NotificationSettingTab />
      </div>
    ),
    preferences: <Preferences />,
    recent_activities: <RecentActivitiesTab />,
    password: <ChangePasswordTab />,
    faq: <FaqTab datas={faq} />,
    privacy: <PrivacyPolicyTab />,
    terms: <TermsConditionTab />,
  };

  // Default tab component
  const defaultTabComponent = (
    <div className="tab-item">
      <PersonalInfoTab />
    </div>
  );

  // Selected tab component based on the current 'tab'
  const selectedTabComponent = tabComponents[tab] || defaultTabComponent;

  return (
    <>
      <Layout>
        <div className="settings-wrapper w-full relative mb-10">
          <div className="main-wrapper w-full">
            {/* heading */}
            <div className="heading w-full mb-6">
              <h1 className="text-26 font-bold text-dark-gray dark:text-white antialiased">
                Settings
              </h1>
            </div>
            <div className="content-container w-full pt-10 rounded-2xl bg-white dark:bg-dark-white  ">
              <div className="content-heading w-full mb-8 lg:px-10 px-4">
                <h1 className="text-xl font-bold text-dark-gray dark:text-white antialiased">
                  Personal Information
                </h1>
              </div>
              <div className="content-body w-full lg:flex lg:px-10 px-4">
                <div className="content-tab-items lg:w-[230px] w-full mr-2">
                  <ul className="overflow-hidden mb-10 lg:mb-0 flex flex-col sm:block lg:flex-col">
                    {tabs.map(({ name, id, title, iconName }) => (
                      <li
                        onClick={() => tabHandler(name)}
                        key={id}
                        className={`flex lg:space-x-4 space-x-2 hover:text-purple transition-all duration-300 ease-in-out items-center cursor-pointer lg:mb-11 mb-2 mr-6 lg:mr-0 float-left lg:float-none overflow-hidden ${
                          tab === name ? "text-purple" : " text-thin-light-gray"
                        }`}
                      >
                        <div>
                          <Icons name={iconName} />
                        </div>
                        <div>
                          <p className="text-18 tracking-wide">{title}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
                <div className="content-body-items flex-1">
                  {selectedTabComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
