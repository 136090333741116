import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ProductCardStyleTwo({
  className,
  datas,
  hidden = false,
  bg
}) {
  // debugger;
  const [addFavorite, setValue] = useState(datas.whishlisted);
  const [options, setOption] = useState(false);
  const favoriteHandler = () => {
    if (!addFavorite) {
      setValue(true);
      toast.success("Added to Favorite List");
    } else {
      setValue(false);
      toast.warn("Remove to Favorite List");
    }
  };
  
  return (
    <div
      className={`card-style-two w-full p-[20px] bg-white dark:bg-dark-white   rounded-2xl section-shadow ${
        className || ""
      }`}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div className="thumbnail-area w-full">
          <div
            className="w-full h-[140px] p-6  rounded-lg overflow-hidden"
            style={{
              // background: `url(${`https://blog.wrenchboard.com/wp-content/uploads/${datas.meta_value}`}) 0% 0% / cover no-repeat`,
              // background: `url(${`${bg}${datas.meta_value}`}) 0% 0% / cover no-repeat`,
              background: `url(${`${bg}${datas.meta_value}`}) no-repeat`,
              backgroundSize: '100%',
            }}
          >
            <div className="product-two-options flex justify-between mb-5 relative">
              <div className="status">
                {datas?.isActive && (
                  <span className="text-xs px-3 py-1.5 tracking-wide rounded-full bg-gold text-white">
                    Active
                  </span>
                )}
              </div>
            </div>
            {hidden && <div className="flex justify-center"></div>}
          </div>
        </div>
        <div className="details-area">
          {/* title */}
          <Link to={`/blog-page?blog_id=${datas.ID}`} className="py-2 mb-2.5" rel="noreferrer">
            <h1 className="font-bold text-xl tracking-wide line-clamp-1 text-dark-gray dark:text-white capitalize">
              {datas.post_title || "dummy title..."}
            </h1>
          </Link>
          <div className="flex justify-between">
            <div className="flex items-center space-x-2"></div>
            <div className="my-1">
              <Link
                // href={datas.guid}
                // target="_blank"
                to={`/blog-page?blog_id=${datas.ID}`}
                className="px-4 py-2.5 text-white text-sm bg-pink rounded-full tracking-wide"
                rel="noreferrer"
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
