import React, { useEffect, useState } from "react";
import usersService from "../../services/UsersService";
import ConfirmNairaWithdraw from "./Popup/ConfirmNairaWithdraw";
import NairaWithdraw from "./Popup/NairaWithdraw";
import VirtualAddCardPopout from "./walletvirtual/VirtualAddCardPopout";
import WalletExtraActionBtn from "./WalletExtraActionBtn";

function WalletAction({ walletItem, payment, openPopUp }) {

  // virtual add card popout
  const [virtualPopup, setVirtualPopup] = useState({ show: false, data: {} }); 

    /*OPENS the virtual add card popout*/
  const openVirtualPopUp = (value) => {
    setVirtualPopup({
      show: true,
      data: { ...value },
    });
  };

  /*Closes the virtual add card popout*/
  const closeVirtualPopUp = () => {
    setVirtualPopup({ show: false, data: {} });
    // dispatch(tableReload({ type: "WALLETTABLE" }));
  };

  const [showNairaWithdraw, setShowNairaWithdraw] = useState({
    show: false,
    state: {},
  }); // DETERMINES WHEN NAIRA WITHDRAWAL POPS UP

  const [showConfirmNairaWithdraw, setShowConfirmNairaWithdraw] = useState({
    show: false,
    state: {},
  }); // DETERMINES WHEN CONFIRM NAIRA WITHDRAWAL POPS UP

  // console.log('Extra Actions', Object.keys(walletItem.extra_actions))
  let extraActions = Object.keys(walletItem.extra_actions) // VARIABLE TO HOLD EXTRA ACTIONS VALUES

  return (
    <div className="counters w-full flex justify-between gap-2">

      {/* EXTRA ACTIONS BTN */}
      <div className="w-1/2 flex justify-start items-center">
      {(extraActions && extraActions?.length > 0) &&
        extraActions.map((action, index) => (
          <div key={index}>
          <button
            onClick={() => {
              action == 'SPEND_FROM_BALANCE' ? 
              setShowNairaWithdraw((prev) => ({ ...prev, show: true })) 
              : action == 'ADD_VIRTUAL_CARD' ? 
              openVirtualPopUp({walletItem})
              :
              null
            }}
            className={`${
              walletItem?.code != "NAIRA" && ""
            } logout-btn btn-gradient text-white`}
          >
            {/* Spend */}
            {walletItem?.extra_actions?.[action][0]?.text}
          </button>
          {/* <WalletExtraActionBtn /> // TO BE USED lATER */}
          </div>
        ))
      }
      </div>

      <div className="w-1/2 flex justify-end items-center">
        <button
          className="logout-btn btn-gradient text-white"
          onClick={() => {
            openPopUp({
              payment: payment,
              currency: walletItem?.description,
            });
          }}
        >
          Add Credit
        </button>
      </div>

      {showNairaWithdraw.show && (
        <NairaWithdraw
          wallet={walletItem}
          action={() => {
            setShowNairaWithdraw((prev) => ({
              ...prev,
              show: !prev.show,
            }));
          }}
          situation={showNairaWithdraw.show}
          state={showNairaWithdraw.state}
          setShowConfirmNairaWithdraw={setShowConfirmNairaWithdraw}
        />
      )}

      {showConfirmNairaWithdraw.show && (
        <ConfirmNairaWithdraw
          wallet={walletItem}
          state={showConfirmNairaWithdraw.state}
          action={() => {
            setShowConfirmNairaWithdraw((prev) => ({
              ...prev,
              show: !prev.show,
            }));
          }}
          setShowNairaWithdraw={setShowNairaWithdraw}
          situation={showConfirmNairaWithdraw.show}
        />
      )}

      {/* VIRTUAL CARD POPOUT */}
      {virtualPopup.show && (
        <VirtualAddCardPopout
          details={virtualPopup.data}
          walletItem={walletItem}
          onClose={closeVirtualPopUp}
          situation={virtualPopup.show}
        />
      )}

    </div>
  );
}

export default WalletAction;
