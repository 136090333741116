import React from "react";
import ForgotPassword from "../components/AuthPages/ForgotPassword";

export default function ForgotPasswordPages() {
  return (
    <>
      <ForgotPassword />
    </>
  );
}
