import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usersService from "../../services/UsersService";
import MyOffersFamilyTable from "../MyTasks/MyOffersFamilyTable";
import FamilyActiveLSlde from "./FamilyActiveLSlde";
import { useDispatch, useSelector } from "react-redux";
import { tableReload } from "../../store/TableReloads";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import HomeModal from "./HomeModal";

export default function FamilyDash({ MyActiveJobList=[], serverImg }) {
  // console.log("PROPS IN FAMILY DASH->", familyOffers?.result_list);

  const dispatch = useDispatch();

  const [firstTimeModal, setFirstTimeModal] = useState(true)
  
  const userApi = new usersService();

  // const trending = MyActiveJobList;
  
  const { familyBannersList } = useSelector((state) => state.familyBannersList);

  const { familyResources:{ tab_categories } } = useSelector((state) => state.familyResources);

  const { userDetails } = useSelector((state) => state?.userDetails);

  let [reloadBanner, setReloadBanner] = useState(0)

  useEffect(()=>{
    if(reloadBanner >= 2){
      dispatch(tableReload({ type: "FAMILYBANNERSLIST" })); // RELOAD FAMILY BANNERS LIST EVERY 10 MINS
      setReloadBanner(0)
    }
    const timer = setInterval(()=>{
      setReloadBanner(prev => prev+1)
    },300000)

    return ()=>{
      clearInterval(timer)
    }
  },[reloadBanner])

  return (
    <>
      <div>
        <div className="home-page-wrapper">
          {/* Header */}
          <div className="text-white mb-4 min-h-[3rem] px-2 w-full flex justify-between items-center rounded-xl bg-family-header-bg">
            <div className="w-full">
              <div className="w-full flex flex-wrap gap-x-4 ">
                <p className="text-lg font-normal leading-5">Welcome</p>
                <div className="">
                  <h1 className="text-lg font-normal leading-5">{`${userDetails?.firstname} ${userDetails?.lastname}`}</h1>
                </div>
              </div>
            </div>
            <div className="py-1 w-full text-sm text-right self-end">
              <p className="leading-4">Last Login: {`${userDetails?.last_login.split(' ')[0]}`}</p>
            </div>
          </div>

          {process.env.REACT_APP_SHOW_NEW_FAMILY_DASH == '1' &&
          <>
            {familyBannersList?.loading ?
            <div className="w-full bg-white rounded-2xl">
              <LoadingSpinner size='10' color='sky-blue' height='h-[20rem]' />
            </div>
            :
            familyBannersList?.result_list && Object.keys(familyBannersList?.result_list).length > 0 ?
              // Loop for Family Banners
              <div className="w-full mb-4 grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
                {Object.keys(familyBannersList?.result_list).map((item, index) => {
                  let content = familyBannersList?.result_list[item]
                  // let action = item == 'recommend' ? 'familymarket' : 'mytask'
                  return (
                    <Link key={item} to={`/${content.banner.action}`} className={`rounded-xl bg-white dark:bg-dark-white shadow-md flex justify-center items-center transition-all duration-300 hover:shadow-sm`}>
                      <div className="h-full w-full">
                        <img className="w-full h-[10rem] object-cover rounded-t-xl" src={content.banner.image} alt='banner image' />
                        <div className="flex flex-col justify-between">
                          <div className="px-2 py-2 border-b border-transparent min-h-[4rem] flex flex-col gap-1">
                            <h1 className="text-lg text-[#083e21] dark:text-white font-bold tracking-wide">{content.banner.text}</h1>
                            <p className="text-sm text-black dark:text-white">{content.banner.description}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
              :
              null
            }
            
            {tab_categories?.data &&
            <div className="mb-4">
              <h1 className="my-4 text-26 font-bold text-dark-gray dark:text-white tracking-wide">Resources</h1>
              <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
                {tab_categories.data.map((item) => {
                  // if(item.enabled){
                  // }
                  return (
                    <Link key={item.uid} to={`/${item?.action}`} className={`group rounded-xl bg-white dark:bg-dark-white shadow-md flex justify-center items-center transition-all duration-300 hover:shadow-sm`}>
                      <div className="h-full w-full">
                        <div className="w-full h-[8rem] rounded-t-xl overflow-hidden">
                          <img className="w-full h-full group-hover:scale-110 object-cover transition-all duration-300" src={item?.banner} alt='banner image' />
                        </div>
                        <div className="flex flex-col justify-between">
                          <div className="px-2 py-1 border-b border-transparent min-h-[2rem] flex justify-between items-center gap-1">
                            <h1 className="text-lg text-[#083e21] dark:text-white font-bold tracking-wide">{item?.content}</h1>
                            <div className="flex justify-center gap-1">
                              <div className="w-1 h-1 bg-slate-400 rounded-full"></div>
                              <div className="w-1 h-1 bg-slate-400 rounded-full"></div>
                              <div className="w-1 h-1 bg-slate-400 rounded-full"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                    )
                  })}
              </div>
            </div>
            }
          </>
          }

          {/* {familyOffers?.result_list && familyOffers?.result_list.length > 0 && (
            <MyOffersFamilyTable
              familyOffers={familyOffers?.result_list}
              image_server={familyOffers?.session_image_server}
              className="mb-10"
            />
          )} */}

          {/* {trending && trending.length > 0 && (
            <FamilyActiveLSlde
              trending={trending}
              className="mb-10"
              image_server={serverImg}
            />
          )} */}
        </div>
      </div>

      
      {/* last_login */}

      {(!familyBannersList?.loading && firstTimeModal) &&
        <HomeModal userDetails={userDetails} action={()=>setFirstTimeModal(prev => !prev)} situation={firstTimeModal} />
      }
    </>
  );
}
