import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobListTable: false,
  pendingListTable: false,
  myTaskTable: false,
  othersInterestedTable: false,
  couponTable: false,
  walletTable: false,
  uploadsTable: false,
  familyBannersListTable: false,
  chatMessageList: false,
  marketTableList: false,
  familyOfferList: false,
  parentFamilyTaskList: false,
  offerInterestListReload: false,
  homeBanners: false,
};

export const tableReloadSlice = createSlice({
  name: "tableReload",
  initialState,
  reducers: {
    tableReload: (state, action) => {
      switch (action.payload.type) {
        case "JOBTABLE":
          state.jobListTable = !state.jobListTable;
          return;
        case "PENDINGTABLE":
          state.pendingListTable = !state.pendingListTable;
          return;
        case "MYTASKTABLE":
          state.myTaskTable = !state.myTaskTable;
          return;
        case "OTHERSINTERESTEDTABLE":
          state.othersInterestedTable = !state.othersInterestedTable;
          return;
        case "COUPONTABLE":
          state.couponTable = !state.couponTable;
          return;
        case "WALLETTABLE":
          state.walletTable = !state.walletTable;
          return;
        case "UPLOADSTABLE":
          state.uploadsTable = !state.uploadsTable;
          return;
        case "FAMILYBANNERSLIST":
          state.familyBannersListTable = !state.familyBannersListTable;
          return;
        case "CHATMESSAGELIST":
          state.chatMessageList = !state.chatMessageList;
          return;
        case "MARKETTABLELIST":
          state.marketTableList = !state.marketTableList;
          return;
        case "FAMILYOFFERLIST":
          state.familyOfferList = !state.familyOfferList;
          return;
        case "PARENTFAMILYTASKLIST": // reloads list of active family task on parent side
          state.parentFamilyTaskList = !state.parentFamilyTaskList;
          return;
        case "OFFERINTERESTLISTRELOAD": // to reload offer interest list of owner when a worker sends interest in a job
          state.offerInterestListReload = !state.offerInterestListReload;
          return;
        case "HOMEBANNERS": // to reload offer interest list of owner when a worker sends interest in a job
          state.homeBanners = !state.homeBanners;
          return;
        default:
          return state;
      }
    },
  },
});

export const { tableReload } = tableReloadSlice.actions;

export default tableReloadSlice.reducer;
