import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import ModalCom from "../../../Helpers/ModalCom";
import LoadingSpinner from "../../../Spinners/LoadingSpinner";
import CustomTimer from "../../../countdown/CustomTimer";
import InputCom from '../../../Helpers/Inputs/InputCom'
import usersService from "../../../../services/UsersService";


const validationSchema = Yup.object().shape({

  country: Yup.string()
    .required("Required"),
  phone_number: Yup.string()
    .min(9, "Invalid")
    .max(11, "Invalid")
    .required("Required"),
  // birthYear: Yup.string()
  //   .required("Required"),
  // birthMonth: Yup.string()
  //   .required("Required"),
  // birthDay: Yup.string()
  //   .required("Required"),
  address: Yup.string()
    .min(5, "Min 3 characters")
    .max(50, "Max 25 characters")
    .required("Required"),
  city: Yup.string()
    .min(2, "Min 3 characters")
    .max(25, "Max 25 characters")
    .required("Required"),
  state: Yup.string()
    .required("Required"),
  zipCode: Yup.string()
    .min(1, "Min 3 characters")
    .max(8, "Max 8 characters")
    .required("Required"),
  dob: Yup.string()
    .required("Required"),
checked: Yup.bool() // use bool instead of boolean
    .oneOf([true], "You must accept the terms and conditions")
});

const VirtualAddCardFamilyPopout = ({ details, onClose, situation, walletItem, familyData }) => {
    // console.log('PROPS', 'DETAILS', details, 'WALLET', walletItem, 'FAMILYDATA', familyData)

  const { userDetails } = useSelector((state) => state.userDetails);

  const countryCode = walletItem?.country

  const userApi = new usersService()

  const [requestStatus, setRequestStatus] = useState({loading: false, status:false, message: ''})

  const [allCountries, setAllCountries] = useState({loading: true, data: []}) // VARIABLE TO HOLD COUNTRY LIST

  const [state, setState] = useState({loading: true, data: {}}) // VARIABLE TO HOLD STATE LIST

  
  let initialValues = {
    // initial values for formik
    country: countryCode ? countryCode : '',
    phone_number: '',
    email: userDetails?.email,
    firstname: familyData?.firstname,
    lastname: familyData?.lastname,
    family_uid: familyData?.uid,    
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    address: '',
    city: '',
    // city: userDetails?.city ? userDetails.city : '',
    state: '',
    zipCode: '',
    dob: '',
    checked: false
  };

  
  const handleSubmit = (values) => {
    let date = new Date(values.dob)
    const reqData = {
      request_type: '100',
      address: values.address,
      city: values.city,
      state: values.state,
      country: values.country,
      postal_code: values.zipCode,
      phone_number: values.phone_number,
      family_uid: values.family_uid,
      // dob_day: values.birthDay,
      // dob_month: values.birthMonth,
      // dob_year: values.birthYear,
      dob_day: Number(date.getDate()),
      dob_month: Number(date.getMonth()) + 1,
      dob_year: Number(date.getFullYear()),
      dob: values.dob
    }
    // console.log('Values', reqData)
    setRequestStatus({loading: true, status:false, message: ''})
    userApi.walletCardFamilyRequest(reqData).then(res => {
      if(res?.data?.internal_return < 0){
        setRequestStatus({loading: false, status:false, message: 'Failed, try again'})
        setTimeout(()=>{
          setRequestStatus({loading: false, status:true, message: ''})
        },4000)
        return 
      }
      setRequestStatus({loading: false, status:true, message: 'Successful'})
      setTimeout(()=>{
        setRequestStatus({loading: false, status:true, message: ''})
        onClose()
      },4000)
    }).catch(err => {
      console.log('ERR', err)
      setRequestStatus({loading: false, status:false, message: 'Unable to complete'})
      setTimeout(()=>{
        setRequestStatus({loading: false, status:false, message: ''})
      },4000)
    })
  }

  useEffect(()=>{
      // Get Country Api
    userApi.getSignupCountryData().then(res =>{
    if(!res?.data?.result_list){
      return setAllCountries({ loading: false, data: [] });
    }
    // if(countryCode){
    //   return setAllCountries({ loading: false, data: res?.data?.result_list?.filter(item => item?.code == countryCode) });
    // }
    setAllCountries({ loading: false, data: res?.data?.result_list });
    }).catch(err => {
      setAllCountries({ loading: false, data: [] });
      console.log('err', err)
    })
  },[])

  useEffect(()=>{
    // GET STATE API
    setState({loading: true, data: {}})
  userApi.getStateFromCountry({country: countryCode}).then(res =>{
  if(!res?.data?.country_state){
    return setState({ loading: false, data: {} });
  }
  setState({ loading: false, data: res?.data?.country_state});
  }).catch(err => {
    setState({ loading: false, data: {} });
    console.log('err', err)
  })
},[initialValues.country])

  return (
    <ModalCom
      action={onClose}
      situation={situation}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="logout-modal-wrapper mx-auto w-[90%] md:w-[768px] h-auto bg-white dark:bg-dark-white lg:rounded-2xl overflow-y-auto">
            <Form className="w-full">
              <div className="modal-header-con">
                <h1 className="modal-title">
                  {walletItem?.description}
                </h1>
                <button
                  type="button"
                  className="modal-close-btn"
                  onClick={onClose}
                >
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                      fill=""
                      fillOpacity="0.6"
                    />
                    <path
                      d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                      fill="#"
                      fillOpacity="0.6"
                    />
                  </svg>
                </button>
              </div>
              <div className='p-4 w-full flex flex-col h-auto md:h-[28rem]'>
                <div className='pb-4'>
                  <h1 className='text-lg md:text-xl flex gap-1'><span className='font-bold'>Name:</span>{familyData.lastname} {familyData.firstname}</h1>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                  {/* left part */}
                  <div className='w-full flex flex-col gap-4'>
                    <div className="field w-full grid md:grid-cols-5 gap-4">
                        <div className="md:col-span-2 field w-full">
                          <label
                            htmlFor="country"
                            className="job-label job-label-flex"
                          >
                            <span>Country</span>
                            {props.errors.country && props.touched.country && (
                              <span className="text-[12px] text-red-500">
                                {props.errors.country}
                              </span>
                            )}
                          </label>
                          <select
                            id="country"
                            name="country"
                            value={props.values.country}
                            className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border`}
                            onChange={props.handleChange}
                            disabled={countryCode ? true : false}
                          >
                            {allCountries.loading ?
                              <option className="text-slate-500 text-lg" value="">
                                Loading...
                              </option>
                            : allCountries.data?.length > 0 ?
                            <>
                              <option className="text-slate-500 text-lg" value="">
                                Select Country
                              </option>
                              {allCountries.data?.map(item => (
                                <option key={item.uid} className="text-slate-500 text-lg" value={item.code}>
                                  {item.country}
                                </option>
                              ))}
                            </>
                            :
                              <option className="text-slate-500 text-lg" value="">
                                Not Found
                              </option>
                            }
                          </select>
                        </div>
                        <div className='md:col-span-3'>
                          <InputCom
                          fieldClass="px-6"
                          label="Phone Number"
                          labelClass="tracking-wide"
                          inputBg="bg-slate-100"
                          inputClass="w-full input-curve lg border border-light-purple"
                          type="text"
                          name="phone_number"
                          value={props.values.phone_number}
                          inputHandler={props.handleChange}
                          error={(props.errors.phone_number && props.touched.phone_number) && props.errors.phone_number}
                          />
                        </div>
                    </div>
                    <InputCom
                      disable={true}
                      fieldClass="px-6"
                      label="Email"
                      labelClass="tracking-wide"
                      inputBg="bg-slate-100"
                      inputClass="input-curve lg border border-light-purple"
                      type="text"
                      name="email"
                      value={props.values.email}
                      inputHandler={props.handleChange}
                      error={(props.errors.email && props.touched.email) && props.errors.email}
                    />
                    <div className="hidden field w-full md:grid-cols-2 gap-4">
                      <InputCom
                        disable={true}
                        fieldClass="px-6"
                        label="Firstname"
                        labelClass="tracking-wide"
                        inputBg="bg-slate-100"
                        inputClass="input-curve lg border border-light-purple"
                        type="text"
                        name="firstname"
                        value={props.values.firstname}
                        inputHandler={props.handleChange}
                        error={(props.errors.firstname && props.touched.firstname) && props.errors.firstname}
                      />
                      <InputCom
                        disable={true}
                        fieldClass="px-6"
                        label="Lastname"
                        labelClass="tracking-wide"
                        inputBg="bg-slate-100"
                        inputClass="input-curve lg border border-light-purple"
                        type="text"
                        name="lastname"
                        value={props.values.lastname}
                        inputHandler={props.handleChange}
                        error={(props.errors.lastname && props.touched.lastname) && props.errors.lastname}
                      />
                    </div>

                    {/* hidden section */}
                    <div className="hidden field w-full flex-col gap-4">
                        <p className='job-label'>Date of Birth</p>
                        <div className='xxs:grid-cols-3 gap-4'>
                          <div className="field w-full">
                            <label
                              htmlFor="birthDay"
                              className="job-label job-label-flex"
                            >
                              <span>Day</span>
                              {props.errors.birthDay && props.touched.birthDay && (
                                <span className="text-[12px] text-red-500">
                                  {props.errors.birthDay}
                                </span>
                              )}
                            </label>
                            <select
                              id="birthDay"
                              name="birthDay"
                              value={props.values.birthDay}
                              className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border`}
                              onChange={props.handleChange}
                            >
                              <>
                              <option className="text-slate-500 text-lg" value=''>
                                  select
                              </option>
                              {day.map(item => (
                                <option key={item} className="text-slate-500 text-lg" value={item}>
                                  {item}
                                </option>
                              ))}
                              </>
                            </select>
                          </div>
                          <div className="field w-full">
                            <label
                              htmlFor="birthMonth"
                              className="job-label job-label-flex"
                            >
                              <span>Month</span>
                              {props.errors.birthMonth && props.touched.birthMonth && (
                                <span className="text-[12px] text-red-500">
                                  {props.errors.birthMonth}
                                </span>
                              )}
                            </label>
                            <select
                              id="birthMonth"
                              name="birthMonth"
                              value={props.values.birthMonth}
                              className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border`}
                              onChange={props.handleChange}
                            >
                              <>
                              <option className="text-slate-500 text-lg" value=''>
                                  select
                              </option>
                              {month.map(item => (
                                <option key={item.value} className="text-slate-500 text-lg" value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                              </>
                            </select>
                          </div>
                          <div className="field w-full">
                            <label
                              htmlFor="birthYear"
                              className="job-label job-label-flex"
                            >
                              <span>Year</span>
                              {props.errors.birthYear && props.touched.birthYear && (
                                <span className="text-[12px] text-red-500">
                                  {props.errors.birthYear}
                                </span>
                              )}
                            </label>
                            <select
                              id="birthYear"
                              name="birthYear"
                              value={props.values.birthYear}
                              className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border`}
                              onChange={props.handleChange}
                            >
                              <>
                              <option className="text-slate-500 text-lg" value=''>
                                  select
                              </option>
                              {year.map(item => (
                                <option key={item} className="text-slate-500 text-lg" value={item}>
                                  {item}
                                </option>
                              ))}
                              </>
                            </select>
                          </div>
                        </div>
                    </div>

                    <div className="field w-full grid md:grid-cols-3 gap-4">
                      <p className='hidden md:flex w-full md:col-span-1 job-label items-center'>Date of Birth</p>
                      <div className='w-full md:col-span-2 '>
                        <InputCom
                          fieldClass="px-6"
                          label="Date of Birth"
                          labelClass="md:hidden tracking-wide"
                          inputBg="bg-slate-100"
                          inputClass="input-curve lg border border-light-purple"
                          type="date"
                          name="dob"
                          value={props.values.dob}
                          inputHandler={props.handleChange}
                          error={(props.errors.dob && props.touched.dob) && props.errors.dob}
                          maxDate={year[0] + '-12-31'}
                          minDate={year[year?.length - 1] + '-01-01'}
                        />
                      </div>
                    </div>
                  </div>

                  {/* right part */}
                  <div className='w-full p-4 flex flex-col gap-4 bg-blue-200 dark:bg-slate-900 rounded-2xl'>
                    <InputCom
                      fieldClass="px-6"
                      label="Address"
                      labelClass="tracking-wide"
                      inputBg="bg-slate-100"
                      inputClass="input-curve lg border border-light-purple"
                      type="text"
                      name="address"
                      value={props.values.address}
                      inputHandler={props.handleChange}
                      error={(props.errors.address && props.touched.address) && props.errors.address}
                    />
                    <InputCom
                      fieldClass="px-6"
                      label="City"
                      labelClass="tracking-wide"
                      inputBg="bg-slate-100"
                      inputClass="input-curve lg border border-light-purple"
                      type="text"
                      name="city"
                      value={props.values.city}
                      inputHandler={props.handleChange}
                      error={(props.errors.city && props.touched.city) && props.errors.city}
                    />
                    {/* <InputCom
                      fieldClass="px-6"
                      label="State/Province"
                      labelClass="tracking-wide"
                      inputBg="bg-slate-100"
                      inputClass="input-curve lg border border-light-purple"
                      type="text"
                      name="state"
                      value={props.values.state}
                      inputHandler={props.handleChange}
                      error={(props.errors.state && props.touched.state) && props.errors.state}
                    /> */}

                    <div className="field w-full grid md:grid-cols-2 gap-4">
                      <div className="field w-full">
                        <label
                          htmlFor="state"
                          className="job-label job-label-flex"
                        >
                          <span>State/Province</span>
                          {props.errors.state && props.touched.state && (
                            <span className="text-[12px] text-red-500">
                              {props.errors.state}
                            </span>
                          )}
                        </label>
                        <select
                          id="state"
                          name="state"
                          value={props.values.state}
                          className={`input-field p-2 mt-3 rounded-full placeholder:text-base text-dark-gray w-full h-[42px] bg-slate-100 focus:ring-0 focus:outline-none border`}
                          onChange={props.handleChange}
                        >
                          {state.loading ?
                              <option className="text-slate-500 text-lg" value="">
                                Loading...
                              </option>
                            : Object.keys(state.data)?.length > 0 ?
                            <>
                              <option className="text-slate-500 text-lg" value="">
                                Select State
                              </option>
                              {Object.keys(state.data)?.map((item, index) => (
                                <option key={index} className="text-slate-500 text-lg" value={item}>
                                  {state?.data[item]}
                                </option>
                              ))}
                            </>
                            :
                              <option className="text-slate-500 text-lg" value="">
                                Not Found
                              </option>
                            }
                        </select>
                      </div>
                  
                      <InputCom
                        fieldClass="px-6"
                        label="Zip Code"
                        labelClass="tracking-wide"
                        inputBg="bg-slate-100"
                        inputClass="input-curve lg border border-light-purple"
                        type="text"
                        name="zipCode"
                        value={props.values.zipCode}
                        inputHandler={props.handleChange}
                        error={(props.errors.zipCode && props.touched.zipCode) && props.errors.zipCode}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-4 w-full job-label'>
                <div className='flex gap-4 items-center'>
                    <input 
                    type='checkbox' 
                    name="checked"
                    className='accent-purple w-4 h-4 p-2 text-purple bg-gray-100 border-gray-300 rounded focus:ring-purple' 
                    onChange={props.handleChange}
                    />
                    <p className='text-[14px] text-justify'>
                        I agree with the <span className='text-bue-600'>cardholder </span> 
                        terms & conditions {props.errors.checked && props.touched.checked && <span className='text-[12px] text-red-500'>{props.errors.checked}</span>}
                    </p>
                </div>
              </div>
              {requestStatus.message &&
              <div className='px-4 my-1'>
                <p className={`text-center text-base py-1 font-bold ${requestStatus.status ? 'bg-emerald-600 text-white' : 'bg-red-100 text-red-600'}`}>{requestStatus.message}</p>
              </div>
              }
              <div className="modal-footer-wrapper grid grid-cols-1 xxs:grid-cols-3">
                  <div className="w-full col-span-1 xxs:col-span-2 xxs:col-start-2 flex justify-between items-center">
                      <button
                      type="button"
                      onClick={onClose}
                      className="custom-btn bg-[#f5a430] text-black text-base"
                      >
                      Cancel
                      </button>

                      <>
                      {requestStatus.loading ?
                      <LoadingSpinner size="6" color="sky-blue" />
                      :
                      <button
                      type="submit"
                      className="custom-btn btn-gradient text-base text-white"
                      disabled={requestStatus.loading || requestStatus.status}
                      >
                      Continue
                      </button>
                      }
                      </>
                  </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </ModalCom>
  );
};

export default VirtualAddCardFamilyPopout;



const day = new Array(31).fill(0).map((_,i) => i+1 )

const month = [
  {value: 1, name: 'January'},
  {value: 2, name: 'February'},
  {value: 3, name: 'March'},
  {value: 4, name: 'April'},
  {value: 5, name: 'May'},
  {value: 6, name: 'June'},
  {value: 7, name: 'July'},
  {value: 8, name: 'August'},
  {value: 9, name: 'September'},
  {value: 10, name: 'October'},
  {value: 11, name: 'November'},
  {value: 12, name: 'December'},
]

const date = new Date().getFullYear()

const year = new Array(100).fill(0).map((_,i) =>  (date-2) - i+1 )