import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import localImgLoad from "../../../lib/localImgLoad";
import { tableReload } from "../../../store/TableReloads";
import { PriceFormatter } from "../../Helpers/PriceFormatter";
import WalletActionVirtual from "./WalletActionVirtual";
import VirtualAddCardPopout from "./VirtualAddCardPopout";
import WrenchTokenPopout from './WrenchTokenPopout'
import WrenchPointPopout from './WrenchPointPopout'

/**
 * Renders a card displaying information about a wallet item.
 */
export default function WalletItemCardVirtual({ walletItem, payment, countries }) {
  
  const dispatch = useDispatch();
  const [virtualPopup, setVirtualPopup] = useState({ name: '', data: {} });

  /**
   * Opens the credit popup.
   * @param {Object} value - The value object.
   */
  const openPopUp = (name, value) => {
    setVirtualPopup({
      name: name.toLowerCase(),
      data: { ...value },
    });
  };

  /**
   * Closes the credit popup and dispatches a table reload action.
   */
  const closePopUp = () => {
    setVirtualPopup({ name: false, data: {} });
    // dispatch(tableReload({ type: "WALLETTABLE" }));
  };

  const currentWalletCurrency = countries?.filter((country) => country.code === walletItem.country);

  const image = walletItem.code
    ? `${walletItem.code.toLowerCase()}.svg`
    : "default.png";


  return (
    <>
      <div
        className="current-balance-widget w-full h-full min-h-[250px] rounded-2xl overflow-hidden flex flex-col items-center gap-4 p-4 justify-between bg-[#498FC3]"
        style={{
          background: `url(${walletItem?.banner}) 0% 0% / cover no-repeat`,
        }}
      >
        <div className="wallet w-full">
          {/* <div className="min-w-[100px] min-h-[100px] max-w-min md:max-w-[150px] max-h-min md:max-h-[150px] rounded-full bg-[#e3e3e3] flex justify-center items-center">
            <img
              src={localImgLoad(`images/currency/${image}`)}
              className="w-full h-full"
              alt="currency-icon"
            />
          </div> */}
          <div className="balance w-full mt-2 flex justify-end">
            <div className="">
              <p className="text-lg sm:text-2xl font-bold text-white tracking-wide mb-2 sm:mb-6">
                {walletItem?.description}
              </p>
              {/* <p className="text-[44px] lg:text-[62px] font-bold text-white tracking-wide leading-10 xxs:scale-100 lg:scale-100 xl:scale-125">
                {PriceFormatter(
                  walletItem.amount * 0.01,
                  walletItem.code,
                  undefined,
                  "text-[2rem]"
                )}
              </p> */}
            </div>
          </div>
        </div>
        
        {/* {walletItem.escrow > 0 ? 
          <p className="text-lg text-white tracking-wide flex justify-center items-center gap-8">
            HOLDINGS :{" "}
            <span className="xxs:scale-100 lg:scale-100 xl:scale-125">
              {PriceFormatter(
                walletItem.escrow * 0.01,
                walletItem.code,
                undefined,
                "text-[1.5rem]"
              )}
            </span>
          </p>
          :
          null
        } */}
        <div className='w-full'>
            <div className="w-full h-[1px] mb-2 bg-white"></div>
            <WalletActionVirtual
                walletItem={walletItem}
                openPopUp={openPopUp}
                btnText={walletItem?.rt_action_text}
            />
        </div>
      </div>

      {virtualPopup.name == 'add virtual debit card' && (
        <VirtualAddCardPopout
          details={virtualPopup.data}
          walletItem={walletItem}
          onClose={closePopUp}
          situation={virtualPopup.name}
        />
      )}
      
      {virtualPopup.name == 'wrench token' && (
        <WrenchTokenPopout
          details={virtualPopup.data}
          walletItem={walletItem}
          onClose={closePopUp}
          situation={virtualPopup.name}
        />
      )}

    {virtualPopup.name == 'wrench points' && (
        <WrenchPointPopout
          details={virtualPopup.data}
          walletItem={walletItem}
          onClose={closePopUp}
          situation={virtualPopup.name}
        />
      )}
    </>
  );
}
