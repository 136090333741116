import React, { createContext, useContext, useEffect, useState } from "react";
import { tableReload } from "../../store/TableReloads";
import { useDispatch, useSelector } from "react-redux";

import io from "socket.io-client";

let SocketIOContext = createContext({})

export default function SocketIOContextProvider({children}) {
    const {userDetails} = useSelector((state) => state?.userDetails); // CHECKS IF USER UID, to determine if user is active

    const dispatch = useDispatch()

    const socket = io.connect(process.env.REACT_APP_PRIMARY_SOCKET); 

    // //Room State
    // const [room, setRoom] = useState("");

    // // Messages States
    // const [message, setMessage] = useState("");
    const [socketMsgReceived, setSocketMsgReceived] = useState("");

    const joinRoom = (room) => {
        if (room !== "") {
        socket.emit("join_room", room);
        }
    };

    const sendMessage = (message, room) => {
        if(message && room){
            socket.emit("send_message", { message, room });
        }
    };

    const marketUpdate = (message, room) => {
        if(message && room){
            socket.emit("marketjob_added", { message, room });
        }
    };

    const parentAssignJobToKid = (message, room) => {
        if(message && room){
            socket.emit("family", { message:{...message}, room });
        }
    };

    const sendJobInterestToOwner = (message, room) => {
        if(message && room){
            socket.emit("marketjob", { message:{...message}, room });
        }
    };

    useEffect(() => {
        socket.on("receive_message", (data) => {
            // setSocketMsgReceived(data.message);
            dispatch(tableReload({type:'CHATMESSAGELIST'})) // dispatches to update chat message sending from owner to worker and vice versa
        });

        socket.on("received_refreshmarket_jobs", (data) => {
            // setSocketMsgReceived(data.message);
            dispatch(tableReload({type:'MARKETTABLELIST'})) // dispatches to update market list on full account
        });

        socket.on("family_actions", (data) => {
            // setSocketMsgReceived(data.message);
            let user_uid = userDetails.account_type == 'FULL' ? userDetails.uid : sessionStorage.getItem('family_uid') // gets user UID
            let {message} = data
            if(message.action == "REFRESH_OFFER" && message.family_uid == user_uid && message.audience == "MEMBER"){  // for refreshing child account when parent assigns a job
                dispatch(tableReload({type:'FAMILYOFFERLIST'})) // dispatches to update family pending/offer list on family side
            }
            if(message.action == "REFRESH_TASK" && message.audience == "PARENT"){  // for refreshing parent account when child accepts or rejects a job
                dispatch(tableReload({type:'PARENTFAMILYTASKLIST'})) // dispatches to update parent family task list on parent side
            }
            if(message.action == "REFRESH_WALLET" && message.family_uid == user_uid && message.audience == "MEMBER"){  // for refreshing child wallet account when parent sends money to kid
                dispatch(tableReload({type:'WALLETTABLE'})) // dispatches to update wallet balance on family side
            }
            // console.log('DATA', data)
        });

        socket.on("marketjob_actions", (data) => { // Triggers refresh on owner side, when somebody sends/shows interest in a job 
            let user_uid = userDetails.account_type == 'FULL' ? userDetails.uid : sessionStorage.getItem('family_uid') // gets user UID
            let {message} = data
            if(message.action == "REFRESH_OFFERS" && message.audience == "MERCHANT" && message.market_uid == user_uid){  // for refreshing job owner offer interest list when any worker sends interest
                dispatch(tableReload({type:'OFFERINTERESTLISTRELOAD'}))
            }
            console.log('data', data)
        });
        // dispatch(tableReload({ type: "HOMEBANNERS" })); // RELOADS HOME BANNERS

    }, [socket]);

    let values = {
        socket,
        sendMessage,
        joinRoom,
        setSocketMsgReceived,
        marketUpdate,
        parentAssignJobToKid,
        sendJobInterestToOwner,
        socketMsgReceived,
        // room,
        // setRoom,
        // message,
        // setMessage,
    }

  return (
    <SocketIOContext.Provider value={values}>
        {children}
    </SocketIOContext.Provider>
  )
}


export const SocketValues = () => {
    return useContext(SocketIOContext)
}
