import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import usersService from "../../../services/UsersService";
import InputCom from "../../Helpers/Inputs/InputCom";
import ModalCom from "../../Helpers/ModalCom";
import LoadingSpinner from "../../Spinners/LoadingSpinner";

function NairaWithdraw({
  wallet,
  action,
  situation,
  state,
  setShowConfirmNairaWithdraw,
}) {
  let MaxNoOfBanks = process.env.REACT_APP_MAX_CREDIT_BANK_ACCOUNT; // HOLDS THE VALUE OF THE MAX NUMBER OF BANKS USER CAN ADD
  const apiCall = new usersService();
  const [tab, setTab] = useState("previous");
  let [requestStatus, setRequestStatus] = useState(false);

  const initialValues = {
    amount: state?.amount || "",
    comment: state?.comment || "",
    previousAccount: {
      recipientID: state?.previousAccount?.recipientID || "",
    },
    newAccount: {
      country: wallet.walletCountry
        ? wallet.walletCountry[0]?.code
        : wallet.country,
      bank: state?.newAccount?.amount || "",
      accountNumber: state?.newAccount?.amount || "",
      accountType: state?.newAccount?.amount || "",
      state: state?.newAccount?.amount || "",
      city: state?.newAccount?.amount || "",
    },
  };
  const [errorMsgs, setErrorMsgs] = useState(initialValues);

  let [sendMoneyFee, setSendMoneyFee] = useState({
    loading: false,
    fee: 0,
    total: 0,
  }); // HOLD THE VALUE FOR walletSEND MONEY FEE
  // Previous Account
  let [recipients, setRecipients] = useState({
    // FOR COUPON HISTORY
    loading: true,
    data: [],
    error: false,
  });
  // New Account
  let [allCountries, setAllCountries] = useState({
    // STATE TO HOLD LIST OF COUNTRIES
    loading: true,
    data: [],
  });
  let [bankName, setBankName] = useState({
    // STATE TO HOLD LIST OF BANK NAME
    loading: false,
    data: [],
  });
  let [accType, setAccType] = useState({
    // STATE TO HOLD LIST ACCOUNT TYPE
    loading: true,
    data: [],
  });

  // Handling card change
  const handleBankOptions = (event) => {
    let bankCountry = wallet.walletCountry
    ? wallet.walletCountry[0]?.code
    : wallet.country
    setBankName((prev) => ({ loading: true, data: [] }));
    apiCall
      .getCountryBank({
        country: bankCountry
      })
      .then((res) => {
        if (res.data.internal_return < 0) {
          setBankName((prev) => ({ loading: false, data: [] }));
          return;
        }
        setBankName((prev) => ({ loading: false, data: res.data.result_list }));
      })
      .catch((error) => {
        setBankName((prev) => ({ loading: false, data: [] }));
      });
  };

  //FUNCTION TO GET RECIPIENT LIST
  const getRecipients = () => {
    apiCall
      .getRecipient()
      .then((res) => {
        if (res.data.internal_return < 0) {
          // success but no data
          setRecipients((prev) => ({ ...prev, loading: false }));
          return;
        }
        setRecipients((prev) => ({
          ...prev,
          loading: false,
          data: res.data.result_list,
        }));
      })
      .catch((error) => {
        setRecipients((prev) => ({ ...prev, loading: false, error: true }));
      });
  };

  // FUNCTION TO GET COUNTRIES
  const getCountry = () => {
    apiCall
      .getSignupCountryData()
      .then((res) => {
        if (res.data.internal_return < 0) {
          setAllCountries((prev) => ({ loading: false, data: [] }));
          return;
        }
        setAllCountries((prev) => ({
          loading: false,
          data: res.data.result_list,
        }));
      })
      .catch((error) => {
        setAllCountries((prev) => ({ loading: false, data: [] }));
      });
  };

  // FUNCTION TO GET ACCOUNT TYPES
  const getAccountTypes = () => {
    apiCall
      .getAccountTypes()
      .then((res) => {
        if (res.data.internal_return < 0) {
          setAccType((prev) => ({ loading: false, data: [] }));
          return;
        }
        setAccType((prev) => ({ loading: false, data: res.data.result_list }));
      })
      .catch((error) => {
        setAccType((prev) => ({ loading: false, data: [] }));
      });
  };

  useEffect(() => {
    handleBankOptions()
    getCountry(); // TO LOAD LIST COUNTRY
    getAccountTypes(); // TO LOAD LIST ACCOUNT TYPES
  }, []);

  //FUNCTION TO GET SEND MONEY FEE
  const getSendMoneyFee = ({ target: { value } }) => {
    setSendMoneyFee({ loading: true, fee: 0, total: 0 });
    let amount = value;
    if (Number(amount) <= 0 || amount == "" || isNaN(amount)) {
      setSendMoneyFee({ loading: false, fee: 0, total: 0 });
      return;
    }
    apiCall
      .getSendMoneyFee(Number(amount * 100))
      .then((res) => {
        setSendMoneyFee({
          loading: false,
          fee: res.data.processing_fee,
          total: res.data.total_amount,
        });
      })
      .catch((error) => {
        setSendMoneyFee({ loading: false, fee: 0, total: 0 });
      });
  };

  //FUNCTION TO HANDLE SUBMIT
  const handleSubmit = async (values, helpers) => {
    try {
      if (!values?.amount) {
        setErrorMsgs({ amount: "amount required" });
        setTimeout(() => setErrorMsgs({ amount: "" }), 3000);
        return;
      } else if (Number(values.amount * 100) > Number(wallet?.transfer_limit)) {
        setErrorMsgs({ amount: "Withdraw limit has been exceeded" });
        setTimeout(() => setErrorMsgs({ amount: "" }), 3000);
        return;
      }

      if (values?.comment?.length >= 50) {
        setErrorMsgs({ comment: "50 chars max" });
        setTimeout(() => setErrorMsgs({ comment: "" }), 1000);
      }

      // form submission logic here
      if (tab === "previous") {
        if (!values?.previousAccount?.recipientID) {
          setErrorMsgs({
            previousAccount: { recipientID: "Please select an option" },
          });
          setTimeout(
            () => setErrorMsgs({ previousAccount: { recipientID: "" } }),
            1000
          );
        }

        setRequestStatus(true);

        // Ensure recipientDetails is an array and not undefined
        const recipientDetails = recipients.data?.filter(
          (item) => item.recipient_uid === values.previousAccount?.recipientID
        );

        // Ensure recipientDetails is not empty
        if (!recipientDetails || recipientDetails.length === 0) {
          // Handle the case when recipientDetails is empty or undefined
          setRequestStatus(false);
          return;
        }

        const stateData = {
          amount: values.amount,
          comment: values.comment,
          choice: "prev",
          wallet_uid: wallet.wallet_uid,
          ...sendMoneyFee,
          details: { ...recipientDetails[0] },
        };

        return setTimeout(() => {
          setRequestStatus(false);
          // navigate("confirm-withdraw-naira", { state: stateData });
          action();
          setShowConfirmNairaWithdraw({ show: true, state: stateData });
        }, 1000);
      }
  
      if (tab === "new") {
        const { accountNumber, accountType, bank, city, country, state } =
          values?.newAccount;

        if (
          !accountNumber ||
          !accountType ||
          !bank ||
          !city ||
          !country ||
          !state
        ) {
          return;
        }

        setRequestStatus(true);

        // Ensure bankDetails is an array and not undefined
        const bankDetails = bankName.data?.filter(
          (item) => item.bank_uid === bank
        );

        // Ensure bankDetails is not empty
        if (!bankDetails || bankDetails.length === 0) {
          // Handle the case when bankDetails is empty or undefined
          setRequestStatus(false);
          return;
        }

        const stateData = {
          amount: values.amount,
          comment: values.comment,
          choice: "new",
          wallet_uid: wallet.wallet_uid,
          ...sendMoneyFee,
          details: { ...bankDetails[0], ...values?.newAccount },
        };

        return setTimeout(() => {
          setRequestStatus(false);
          action();
          setShowConfirmNairaWithdraw({ show: true, state: stateData });
        }, 1000);
      }
    } catch (errors) {
      // If validation fails, handle the validation errors
      throw new Error("Validation errors:", errors);
    }
  };

  useEffect(() => {
    getRecipients();
  }, []);

  console.log("Testing Wallet Country", wallet?.walletCountry[0]?.code);

  return (
    <ModalCom action={action} situation={situation}>
      <div className="logout-modal-wrapper w-[90%] md:w-[768px] h-auto bg-white dark:bg-dark-white lg:rounded-2xl overflow-y-auto">
        <div className="w-full">
          <div className="modal-header-con">
            <h1 className="modal-title">
              {`Withdraw from ${wallet.description} Wallet : ${wallet.symbol}${(
                wallet.amount * 0.01
              ).toFixed(2)}`}
            </h1>
            <button
              type="button"
              className="modal-close-btn"
              onClick={action}
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 16.16C36 17.4399 36 18.7199 36 20.0001C35.7911 20.0709 35.8636 20.2554 35.8385 20.4001C34.5321 27.9453 30.246 32.9248 22.9603 35.2822C21.9006 35.6251 20.7753 35.7657 19.6802 35.9997C18.4003 35.9997 17.1204 35.9997 15.8401 35.9997C15.5896 35.7086 15.2189 35.7732 14.9034 35.7093C7.77231 34.2621 3.08728 30.0725 0.769671 23.187C0.435002 22.1926 0.445997 21.1199 0 20.1599C0 18.7198 0 17.2798 0 15.8398C0.291376 15.6195 0.214408 15.2656 0.270759 14.9808C1.71321 7.69774 6.02611 2.99691 13.0428 0.700951C14.0118 0.383805 15.0509 0.386897 15.9999 0C17.2265 0 18.4532 0 19.6799 0C19.7156 0.124041 19.8125 0.136067 19.9225 0.146719C27.3 0.868973 33.5322 6.21922 35.3801 13.427C35.6121 14.3313 35.7945 15.2484 36 16.16ZM33.011 18.0787C33.0433 9.77105 26.3423 3.00309 18.077 2.9945C9.78479 2.98626 3.00344 9.658 2.98523 17.8426C2.96667 26.1633 9.58859 32.9601 17.7602 33.0079C26.197 33.0577 32.9787 26.4186 33.011 18.0787Z"
                  fill=""
                  fillOpacity="0.6"
                />
                <path
                  d="M15.9309 18.023C13.9329 16.037 12.007 14.1207 10.0787 12.2072C9.60071 11.733 9.26398 11.2162 9.51996 10.506C9.945 9.32677 11.1954 9.0811 12.1437 10.0174C13.9067 11.7585 15.6766 13.494 17.385 15.2879C17.9108 15.8401 18.1633 15.7487 18.6375 15.258C20.3586 13.4761 22.1199 11.7327 23.8822 9.99096C24.8175 9.06632 26.1095 9.33639 26.4967 10.517C26.7286 11.2241 26.3919 11.7413 25.9133 12.2178C24.1757 13.9472 22.4477 15.6855 20.7104 17.4148C20.5228 17.6018 20.2964 17.7495 20.0466 17.9485C22.0831 19.974 24.0372 21.8992 25.9689 23.8468C26.9262 24.8119 26.6489 26.1101 25.4336 26.4987C24.712 26.7292 24.2131 26.3441 23.7455 25.8757C21.9945 24.1227 20.2232 22.3892 18.5045 20.6049C18.0698 20.1534 17.8716 20.2269 17.4802 20.6282C15.732 22.4215 13.9493 24.1807 12.1777 25.951C11.7022 26.4262 11.193 26.7471 10.4738 26.4537C9.31345 25.9798 9.06881 24.8398 9.98589 23.8952C11.285 22.5576 12.6138 21.2484 13.9387 19.9355C14.5792 19.3005 15.2399 18.6852 15.9309 18.023Z"
                  fill="#"
                  fillOpacity="0.6"
                />
              </svg>
            </button>
          </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {(props) => {
                return (
                  <Form className="transfer-fund-info">
                    <div className="add-fund w-full md:p-8 p-4 bg-white dark:bg-dark-white h-full rounded-2xl">
                      {/* Amount Form */}
                      <div className="flex flex-col gap-2">
                        <div className="field w-full">
                          <InputCom
                            fieldClass="px-4 text-end"
                            parentClass="flex items-center gap-1"
                            labelClass="flex-[0.2] mb-0"
                            inputClass="flex-[0.8] max-w-[12rem]"
                            label="Amount:"
                            type="number"
                            name="amount"
                            placeholder="0"
                            value={props.values.amount}
                            inputHandler={props.handleChange}
                            blurHandler={(e) => {
                              getSendMoneyFee(e);
                            }}
                            error={errorMsgs.amount && errorMsgs.amount}
                          />
                        </div>

                        <div className="field w-full">
                          <InputCom
                            fieldClass="px-4"
                            parentClass="flex items-center gap-1"
                            labelClass="flex-[0.2] mb-0"
                            inputClass="flex-[0.8] max-w-[12rem]"
                            label="Fee:"
                            type="text"
                            name="fee"
                            direction="rtl"
                            value={
                              sendMoneyFee.loading
                                ? "loading"
                                : (sendMoneyFee.fee * 0.01).toFixed(2)
                            }
                            disable={true}
                          />
                        </div>

                        <div className="field w-full">
                          <InputCom
                            fieldClass="px-4"
                            parentClass="flex items-center gap-1"
                            labelClass="flex-[0.2] mb-0"
                            inputClass="flex-[0.8] max-w-[12rem]"
                            label="Total:"
                            type="text"
                            name="total"
                            direction="rtl"
                            value={
                              sendMoneyFee.loading
                                ? "loading"
                                : (sendMoneyFee.total * 0.01).toFixed(2)
                            }
                            disable={true}
                          />
                        </div>

                        <div className="field w-full mb-3 flex gap-1 justify-between">
                          <label className="text-[#181c32] dark:text-white text-base font-semibold flex flex-[0.2] mt-2.5">
                            Comment:
                          </label>
                          <textarea
                            style={{ resize: "none" }}
                            className="text-base px-4 py-2 rounded-md sm:max-w-[550px] max-w-[250px] text-dark-gray dark:text-white w-full bg-slate-100 dark:bg-[#11131F]  focus:ring-0 focus:outline-none flex-[0.8]"
                            name="comment"
                            value={props.values.comment}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            cols="30"
                            rows="2"
                          />
                        </div>
                      </div>

                      {/* Account Selector */}
                      <div className=" flex items-center gap-[8rem]">
                        <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-tighter my-1">
                          To:
                        </h1>
                        <div className="my-1 flex items-center gap-2">
                          <label
                            onClick={() => setTab("previous")}
                            htmlFor="previous"
                            className="cursor-pointer flex items-center gap-1 text-dark-gray dark:text-white"
                          >
                            <input
                              type="radio"
                              id="previous"
                              name="choice"
                              value="previous"
                              checked={tab === "previous"}
                              className={`p-2 text-lg font-bold text-slate-600 dark:text-white border pointer-events-none w-7 h-7 ${
                                tab == "previous" ? "" : ""
                              } tracking-wide transition duration-200`}
                            />
                            Previous Account
                          </label>
                          <label
                            onClick={() => setTab("new")}
                            htmlFor="new"
                            className={`cursor-pointer flex items-center gap-1 text-dark-gray dark:text-white ${
                              recipients.data.length >= MaxNoOfBanks
                                ? "pointer-events-none"
                                : ""
                            }`}
                          >
                            <input
                              id="new"
                              type="radio"
                              name="choice"
                              value="new"
                              checked={tab === "new"}
                              className={`p-2 text-lg font-bold text-slate-600 dark:text-white border pointer-events-none w-7 h-7 ${
                                tab == "new" ? "" : ""
                              }  tracking-wide transition duration-200`}
                            />
                            New Account{" "}
                            {recipients.data.length >= MaxNoOfBanks && (
                              <span className="text-[14px] text-red-500">
                                Max Reached
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      <div className="">
                        {tab == "previous" && (
                          <>
                            <div className="w-full">
                              <div className="relative my-3 md:flex items-center px-4">
                                <div className="transfer-input w-full flex items-center justify-end">
                                  {/* <label className="text-[#181c32] dark:text-white text-base font-semibold block flex-[0.2] mb-0 mt-3"></label> */}
                                  <div className="flex flex-col gap-3 flex-[0.8] items-center">
                                    <select
                                      className="sm:w-full w-48 text-dark-gray border-slate-300 outline-0 flex-[0.8] my-3 rounded-full p-2 outline-none text-base dark:text-gray-100 bg-[#FAFAFA] dark:bg-[#11131F] border appearance-none"
                                      value={
                                        props.values.previousAccount?.recipientID
                                      }
                                      name="previousAccount.recipientID"
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                    >
                                      {recipients.loading ? (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          Loading...
                                        </option>
                                      ) : recipients.data.length ? (
                                        <>
                                          <option
                                            className="text-slate-500 text-lg"
                                            value=""
                                          >
                                            {errorMsgs.previousAccount
                                              ?.recipientID
                                              ? errorMsgs.previousAccount
                                                  .recipientID
                                              : "Select an account"}
                                          </option>
                                          {recipients.data.map((item, index) => (
                                            <option
                                              key={index}
                                              value={item.recipient_uid}
                                              className="text-slate-500 text-lg"
                                            >
                                              {item.recipient}
                                            </option>
                                          ))}
                                        </>
                                      ) : recipients.error ? (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          Could'nt load, !
                                        </option>
                                      ) : (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          No Recipient Found!
                                        </option>
                                      )}
                                    </select>
                                    <div className="flex justify-end relative w-full">
                                      {errorMsgs.previousAccount?.recipientID &&
                                        errorMsgs.previousAccount
                                          ?.recipientID && (
                                          <p className="sm:text-sm text-[12px] text-red-500 absolute sm:top-1 -top-20 sm:left-0 left-[160px]">
                                            {
                                              errorMsgs.previousAccount
                                                ?.recipientID
                                            }
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="h-[6.1rem]"></div>
                          </>
                        )}

                        {tab == "new" &&
                          (recipients.loading ? (
                            <div className="mt-3 flex flex-col w-full h-[188px] justify-center items-center">
                              <LoadingSpinner size="10" color="sky-blue" />
                            </div>
                          ) : recipients.data.length < MaxNoOfBanks ? (
                            <div className="w-full mt-3 rounded-md bg-slate-100 dark:bg-dark-white">
                              <div className="relative fields w-full flex flex-col p-4">
                                <div className="flex flex-[2] min-h-[52px]">
                                  {/* country */}
                                  <div className="add-recipient w-full flex items-center flex-1 xl:mb-0">
                                    <label
                                      htmlFor="newAccount.country"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex flex-[0.3]"
                                    >
                                      Country{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="w-full text-base p-2 text-dark-gray bg-white dark:bg-[#11131F] dark:text-white border border-slate-300 outline-0 flex-[0.6] rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding tracking-[1.5px] flex items-center pointer-events-none">
                                      <span className="text-slate-500 text-lg italic">
                                        {wallet.walletCountry[0]?.country}
                                      </span>
                                    </div>
                                    {/* <select
                                      className="w-full text-base p-2 text-dark-gray dark:text-white border border-slate-300 outline-0 flex-[0.6] rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding tracking-[1.5px]"
                                      name="newAccount.country"
                                      value={props.values.newAccount?.country}
                                      onChange={(e) => {
                                        props.handleChange(e);
                                        handleBankOptions(e);
                                      }}
                                    >
                                      {allCountries.loading ? (
                                        <option
                                        className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          Loading...
                                        </option>
                                      ) : allCountries.data?.length ? (
                                        <>
                                          <option
                                            className="text-slate-500 text-lg"
                                            value=""
                                          >
                                            {errorMsgs.newAccount?.country
                                              ? errorMsgs.newAccount.country
                                              : "Select..."}
                                          </option>
                                          {allCountries.data.map((item, index) => (
                                            <option
                                              key={index}
                                              className="text-slate-500 text-lg"
                                              value={item[0]}
                                            >
                                              {item[1]}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          No Options Found!
                                        </option>
                                      )}
                                    </select> */}
                                  </div>

                                  {/* bank name */}
                                  <div className="add-recipient w-full flex items-center flex-1">
                                    <label
                                      htmlFor="newAccount.bank"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex flex-[0.4] tracking-[1.5px]"
                                    >
                                      Bank Name{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                      className="w-full text-base p-2 bg-white dark:bg-[#11131F] text-dark-gray dark:text-white border border-slate-300 outline-0 flex-[0.6] rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding "
                                      name="newAccount.bank"
                                      value={props.values.newAccount?.bank}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                    >
                                      {bankName.loading ? (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          Loading...
                                        </option>
                                      ) : bankName.data?.length ? (
                                        <>
                                          <option
                                            className="text-slate-500 text-lg"
                                            value=""
                                          >
                                            Select...
                                          </option>
                                          {bankName.data?.map((item, index) => (
                                            <option
                                              key={index}
                                              className="text-slate-500 text-lg"
                                              value={item.bank_uid}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          {allCountries.data?.length
                                            ? "Select..."
                                            : "No Options Found!"}
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="flex flex-[2] gap-4">
                                  {/* ACCOUNT NUMBER */}
                                  <div className="field w-full flex-[1.4] flex items-center gap-2">
                                    <label
                                      htmlFor="newAccount.accountNumber"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex items-center flex-1"
                                    >
                                      Account Number{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <InputCom
                                      fieldClass="px-6 tracking-[1.5px]"
                                      inputClass="flex items-center max-w-[15rem]"
                                      type="text"
                                      name="newAccount.accountNumber"
                                      placeholder="Account No"
                                      maxLength={10}
                                      value={
                                        props.values.newAccount?.accountNumber
                                      }
                                      inputHandler={props.handleChange}
                                      blurHandler={props.handleBlur}
                                    />
                                  </div>

                                  {/* Account Type */}
                                  <div className="add-recipient w-full flex flex-1 items-center">
                                    <label
                                      htmlFor="newAccount.accountType"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex flex-[0.3]"
                                    >
                                      Type <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                      className="w-full text-base p-2 text-dark-gray bg-white dark:bg-[#11131F] dark:text-white border border-slate-300 outline-0 flex-[0.6] rounded-full h-[42px] overflow-hidden relative font-medium leading-6 bg-clip-padding flex-grow tracking-[1.5px]"
                                      name="newAccount.accountType"
                                      value={props.values.newAccount?.accountType}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                    >
                                      {accType.loading ? (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          Loading...
                                        </option>
                                      ) : accType.data?.length ? (
                                        <>
                                          <option
                                            className="text-slate-500 text-lg"
                                            value=""
                                          >
                                            Select...
                                          </option>
                                          {accType.data?.map((item, index) => (
                                            <option
                                              key={index}
                                              className="text-slate-500 text-lg"
                                              value={item.value}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        <option
                                          className="text-slate-500 text-lg"
                                          value=""
                                        >
                                          No Options Found!
                                        </option>
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="flex items-center flex-1">
                                  {/* state */}
                                  <div className="field w-full flex items-center gap-4 flex-[0.4]">
                                    <label
                                      htmlFor="newAccount.state"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex flex-[0.4]"
                                    >
                                      State{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <InputCom
                                      fieldClass="px-6 tracking-[1.5px]"
                                      inputClass="max-w-[10rem]"
                                      type="text"
                                      name="newAccount.state"
                                      placeholder="State/Province"
                                      value={props.values.newAccount?.state}
                                      inputHandler={props.handleChange}
                                      blurHandler={props.handleBlur}
                                    />
                                  </div>

                                  {/* city */}
                                  <div className="field w-full flex items-center flex-[0.4]">
                                    <label
                                      htmlFor="newAccount.city"
                                      className="input-label text-[#181c32] dark:text-white text-base font-semibold inline-flex flex-[0.4]"
                                    >
                                      City <span className="text-red-500">*</span>
                                    </label>
                                    <InputCom
                                      fieldClass="px-6 tracking-[1.5px]"
                                      type="text"
                                      inputClass="max-w-[10rem]"
                                      name="newAccount.city"
                                      placeholder="City"
                                      value={props.values.newAccount?.city}
                                      inputHandler={props.handleChange}
                                      blurHandler={props.handleBlur}
                                    />
                                  </div>
                                </div>

                                {/* end of inputs for new accounts */}
                              </div>
                            </div>
                          ) : (
                            <div className="mt-3 flex w-full h-[188px] justify-center items-center"></div>
                          ))}
                      </div>

                    </div>
                    
                    <div className="modal-footer-wrapper grid grid-cols-1 xxs:grid-cols-3">
                      <div className="w-full col-span-1 xxs:col-span-2 xxs:col-start-2 flex justify-between items-center">
                        <button
                          type="button"
                          onClick={action}
                          className="custom-btn bg-[#f5a430] text-black text-base"
                        >
                          Cancel
                        </button>

                        <button
                          type="submit"
                          disabled={
                            props.isSubmitting || sendMoneyFee.loading
                              ? true
                              : false
                          }
                          className="custom-btn btn-gradient text-base text-white"
                        >
                          {requestStatus ? (
                            <LoadingSpinner size="6" color="sky-blue" />
                          ) : (
                            "Continue"
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
        </div>
      </div>
    </ModalCom>
  );
}

export default NairaWithdraw;
