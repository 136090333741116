import {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import Home from "../components/Home";

export default function HomePages() {
  const { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  const [bannerOptions, setBannerOptions] = useState({})



  useEffect(()=>{
    const bannerOptions = {
      bannerList: commonHeadBanner?.result_list,
      dashTypes: commonHeadBanner?.home_dash_type,
      offersList: commonHeadBanner?.offers_list,
      imageServer: commonHeadBanner?.session_image_server
    };
    setBannerOptions(bannerOptions)
  },[commonHeadBanner])

  return (
    <>
      <Home {...bannerOptions} />
    </>
  );
}
