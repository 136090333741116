import { useNavigate, Link } from "react-router-dom";
import AuthLayout from "../AuthLayout2";
import WrenchBoard from "../../../assets/images/wrenchboard-logo-text.png";

export default function VerifyYou() {
  const navigate = useNavigate();
  return (
    <>
      <AuthLayout slogan="Welcome to WrenchBoard">
        <div className="w-full">
          <div className="flex place-content-center">
            <div className="w-10/12">
              <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                <h1 className="text-[#181c32] font-semibold dark:text-white mb-3 leading-[27.3px] text-[22.75px]">
                  Let's verify your email now
                </h1>
                <span className="text-gray-400 font-medium text-[16.25px] leading-[24.375px]">
                  Check your email.
                </span>
              </div>
              <div className="input-area">
              <div className="mb-5">
                  <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
                    Please <span className="font-semibold tracking-wide">verify your email</span> to secure your account.
                  </p>
                </div>
                <div className="mb-5">
                  <p className="text-[14px] leading-[19px] text-center text-[#181c32]">
                    If you don't see the confirmation email, check your <span className='font-semibold tracking-wide'>Junk</span> or <span className='font-semibold tracking-wide'>Spam</span> folder and mark it as "Not Junk"
                  </p>
                </div>
              </div>
              <div className="signin-area flex justify-center mb-3.5">
                <button
                  onClick={() => navigate("/")}
                  type="button"
                  className={`rounded-[0.475rem] mb-6 text-[15px] font-semibold text-[#009ef7] hover:text-white flex justify-center bg-[#f1faff] hover:bg-[#009ef7] transition-all duration-300 items-center py-[0.8875rem] px-[1.81rem]`}
                >
                  <span>Return Home</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
