import React, { useState } from 'react'
import { localImgLoad } from '../../../../lib'
import { PriceFormatter } from '../../../Helpers/PriceFormatter'
import FamilyAddFundPopout from './FamilyAddFundPopout'
import VirtualAddCardFamilyPopout from './VirtualAddCardFamilyPopout'

function Wallet({wallet, familyData, setFamilyWalletReload}) {
    const [addFundPopout, setAddFundPopout] = useState({ show: false, data: {} })
    console.log('WALLET', wallet.brand)

    // virtual add card popout
    const [virtualPopup, setVirtualPopup] = useState({ show: false, data: {} }); 

    /*OPENS the virtual add card popout*/
    const openVirtualPopUp = (value) => {
      setVirtualPopup({
        show: true,
        data: { ...value },
      });
    };

    /*Closes the virtual add card popout*/
    const closeVirtualPopUp = () => {
      setVirtualPopup({ show: false, data: {} });
      // dispatch(tableReload({ type: "WALLETTABLE" }));
    };

  // console.log('Extra Actions', Object.keys(wallet.extra_actions))
  let extraActions = Object.keys(wallet.extra_actions) // VARIABLE TO HOLD EXTRA ACTIONS VALUES

  return (
    <div className='w-full p-4 bg-[aliceblue] rounded-lg'
    // style={{
    //   background: `url(${background}) 0% 0% / cover no-repeat`,
    // }}
  >
    <div className="w-full flex justify-start items-center gap-3">
      <div className="min-w-[50px] min-h-[50px] max-w-min md:max-w-[80px] max-h-min md:max-h-[80px] rounded-full bg-[#e3e3e3] flex justify-center items-center">
        <img
          src={wallet.icon}
          className="w-full h-full"
          alt="currency-icon"
        />
      </div>
      <div className="w-full flex flex-col">
        {wallet?.brand && wallet?.card_last4 ? // FOR VIRTUAL CARD NUMBER ELSE WALLET BALANCE DISPLAY
          <div className="w-full flex flex-col gap-2">
            {/* <p className="text-base md:text-lg text-thin-light-gray tracking-wide">
              Balance:
            </p> */}
            <p className="text-base md:text-lg font-bold text-purple leading-10">
              {wallet.brand} <span> **** **** ***** </span> {wallet?.card_last4}
            </p>
          </div>
          :
          <div className="w-full flex gap-2">
            <p className="text-base md:text-lg text-thin-light-gray tracking-wide">
              Balance:
            </p>
            <p 
              className="text-base md:text-lg font-bold text-purple tracking-wide leading-10"
              // className="text-[44px] lg:text-[62px] font-bold text-white tracking-wide leading-10 xxs:scale-100 lg:scale-100 xl:scale-125"
              >
              {PriceFormatter(
                Number(wallet.amount) * 0.01,
                wallet.code,
                wallet.country,
                ""
              )}
            </p>
          </div>
        }
        {/* BTN */}
        <div className='mt-1 flex justify-end items-center gap-2'>
            <button
                className="w-[150px] h-[48px] rounded-full text-base text-white bg-sky-500 hover:bg-sky-400"
                name="add"
                onClick={()=>{
                    setAddFundPopout({ show: true, data: {} });
                }}
            >
                Add Money
            </button>
            
            {/* EXTRA ACTIONS BTN */}
            {(extraActions && extraActions?.length > 0) &&
              extraActions.map((action, index) => (
                <div key={index}>
                  <button
                    onClick={() => { 
                      action == 'ADD_VIRTUAL_CARD' ? 
                      openVirtualPopUp({wallet})
                      :
                      null
                    }}
                    className={`w-[150px] h-[48px] rounded-full text-base text-white bg-[#4687ba] hover:bg-[#009ef7]`}
                  >
                    {/* Add Card */}
                    {wallet?.extra_actions?.[action][0]?.text}
                  </button>
                  {/* <WalletExtraFamilyActionBtn /> // TO BE USED lATER IF NEED BE */}
                </div>
              ))
            }

        </div>
      </div>
    </div>

    {/* MODAL TO ADD FUND */}
    {addFundPopout.show &&
        <FamilyAddFundPopout 
            action={() => {
                setAddFundPopout({ show: false, data: {} });
                setFamilyWalletReload(prev => !prev) // TO RELOAD FAMILY WALLET // DETERMINES WHEN TO RELOAD FAMILY WALLET TAB/PAGE
            }}
            situation={addFundPopout.show}
            wallet={wallet}
            familyData={familyData}
        />
    }

    {/* VIRTUAL CARD POPOUT */}
    {virtualPopup.show && (
      <VirtualAddCardFamilyPopout
        details={virtualPopup.data}
        walletItem={wallet}
        onClose={closeVirtualPopUp}
        situation={virtualPopup.show}
        familyData={familyData}
      />
    )}
</div>
  )
}

export default Wallet