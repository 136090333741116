import React from "react";
import Reminders from "../components/Reminders";

export default function RemindersPage() {
  return (
    <>
      <Reminders />
    </>
  );
}
