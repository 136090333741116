import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import background from "../../assets/images/bg-sky-blue.jpg"; //shape/balance-bg.svg";
import localImgLoad from "../../lib/localImgLoad";
import { tableReload } from "../../store/TableReloads";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import CreditPopup from "./Popup/CreditPopup";
import WalletAction from "./WalletAction";

/**
 * Renders a card displaying information about a wallet item.
 */
export default function WalletItemCard({ walletItem, payment, countries }) {
  
  const dispatch = useDispatch();
  const [creditPopup, setCreditPopup] = useState({ show: false, data: {} });

  /**
   * Opens the credit popup.
   * @param {Object} value - The value object.
   */
  const openPopUp = (value) => {
    setCreditPopup({
      show: true,
      data: { ...value },
    });
  };

  /**
   * Closes the credit popup and dispatches a table reload action.
   */
  const closePopUp = () => {
    setCreditPopup({ show: false, data: {} });
    dispatch(tableReload({ type: "WALLETTABLE" }));
  };

  const currentWalletCurrency = countries?.filter((country) => country.code === walletItem.country);

  const image = walletItem.code
    ? `${walletItem.code.toLowerCase()}.svg`
    : "default.png";


  return (
    <>
      <div
        className="current-balance-widget w-full h-full min-h-[250px] rounded-2xl overflow-hidden flex flex-col items-center gap-4 p-4 justify-between bg-[#498FC3]"
        style={{
          background: `url(${walletItem?.banner}) 0% 0% / cover no-repeat`,
        }}
      >
        <div className="wallet w-full">
          {/* <div className="min-w-[100px] min-h-[100px] max-w-min md:max-w-[150px] max-h-min md:max-h-[150px] rounded-full bg-[#e3e3e3] flex justify-center items-center">
            <img
              src={localImgLoad(`images/currency/${image}`)}
              className="w-full h-full"
              alt="currency-icon"
            />
          </div> */}
          <div className="balance w-full mt-2 flex justify-end">
            <div className="flex flex-col items-center">
              <p className="text-base sm:text-lg text-white tracking-wide mb-2 sm:mb-6">
                Current Balance
              </p>
              {walletItem?.brand && walletItem?.card_last4 ? // FOR VIRTUAL CARD NUMBER ELSE WALLET BALANCE DISPLAY
              <p className="text-2xl font-bold text-white dark:text-white leading-10">
                {walletItem.brand} <span> **** **** ***** </span> {walletItem?.card_last4}
              </p>
              :
              <p className="text-[44px] lg:text-[62px] font-bold text-white tracking-wide leading-10">
                {PriceFormatter(
                  walletItem.amount * 0.01,
                  walletItem.code,
                  undefined,
                  "text-[2rem]"
                )}
              </p>
              }
            </div>
          </div>
        </div>
        
        {walletItem.escrow > 0 ? 
        <div className='w-full flex justify-end'>
          <p className="text-lg text-white tracking-wide flex justify-center items-center gap-8">
            HOLDINGS :{" "}
            <span className="">
              {PriceFormatter(
                walletItem.escrow * 0.01,
                walletItem.code,
                undefined,
                "text-[1.5rem]"
              )}
            </span>
          </p>
        </div>
        :
        null
        }

        <div className='w-full'>
          <div className="w-full h-[1px] mb-2 bg-white"></div>
          <WalletAction
            walletItem={{ ...walletItem, walletCountry: currentWalletCurrency }}
            payment={payment}
            openPopUp={openPopUp}
          />
        </div>
      </div>

      {creditPopup.show && (
        <CreditPopup
          details={creditPopup.data}
          walletItem={walletItem}
          onClose={closePopUp}
          situation={openPopUp}
        />
      )}
    </>
  );
}
