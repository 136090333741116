import React from 'react'

export default function WalletExtraActionBtn() {
  return (
    <>
      {/* <button
        onClick={() => {
          walletItem?.code == 'NAIRA' ? setShowNairaWithdraw((prev) => ({ ...prev, show: true })) : openVirtualPopUp({walletItem})
        }}
        className={`${
          walletItem?.code != "NAIRA" && ""
        } logout-btn btn-gradient text-white`}
      >
        {walletItem?.code == 'NAIRA' ? walletItem?.extra_actions?.SPEND_FROM_BALANCE[0]?.text : walletItem?.extra_actions?.ADD_VIRTUAL_CARD[0]?.text}
      </button> */}
    </>
  )
}
