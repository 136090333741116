import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usersService from "../../services/UsersService";
import Layout from "../Partials/Layout";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import CustomBreadcrumb from "../Breadcrumb/CustomBreadcrumb";
const FamilyWalletBox = lazy(() => import("./FamilyWalletBox"));

const FamilyWalletCon = () => {
  const apiCall = new usersService();
  // const { walletDetails } = useSelector((state) => state?.walletDetails); // WALLET STORE

  const {userDetails} = useSelector((state) => state?.userDetails); // GETS USER INFO

  const { walletTable } = useSelector((state) => state.tableReload);

  const [paymentHistory, setPaymentHistory] = useState({
    loading: true,
    data: [],
  });

  const [familyWalletBal, setFamilyWalletBal] = useState({
    loading: true,
    data: []
  });

  const getFamilyWalletBal = () => {
    setFamilyWalletBal({loading:true, data: []})
    apiCall.getFamilyWallet({family_uid: userDetails.uid}).then(res => {
      setFamilyWalletBal({loading:false, data: res?.data?.result_list})
    }).catch(error => {
      setFamilyWalletBal({loading:false, data: []})
    })
  };

  const getPaymentHistory = () => {
    apiCall
      .getPaymentHx()
      .then((res) => {
        if (res.data.internal_return < 0) {
          setPaymentHistory({ loading: false, data: [] });
        } else {
          setPaymentHistory({ loading: false, data: res.data?.result_list });
          // console.log('Hist', res.data?.result_list)
        }
      })
      .catch(() => {
        setPaymentHistory({ loading: false, data: [] });
      });
  };

  useEffect(() => {
    // getPaymentHistory();
    getFamilyWalletBal()
  }, [walletTable]);

  return (
    <Layout>
      <div className='mb-4'>
        <CustomBreadcrumb 
            title={'Wallet'} 
            breadcrumb = {
            [
                { link: "/", title: "Home" },
                { link: '/family-wallet', title: 'Wallet', active: true},
            ]
            }
        />
      </div>
      <Suspense fallback={
        <div className="bg-white rounded-2xl">
          <LoadingSpinner size="16" color="sky-blue" height='h-[30rem]' />
        </div>
      }>
        <FamilyWalletBox
          wallet={familyWalletBal}
          // payment={paymentHistory}
        />
      </Suspense>
    </Layout>
  );
};

export default FamilyWalletCon;
