import { useState } from "react";
import dataImage2 from "../../assets/images/data-table-user-2.png";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import PaginatedList from "../Pagination/PaginatedList";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import MarketPopUp from "../MarketPlace/PopUp/MarketPopUp";
import localImgLoad from "../../lib/localImgLoad";

export default function MyWaitingJobTable({ MyJobList, className }) {
  let [jobPopout, setJobPopout] = useState({ show: false, data: {} }); // STATE TO HOLD THE VALUE OF THE ALERT DETAILS AND DETERMINE WHEN TO SHOW

  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentActiveJobList = MyJobList?.result_list?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white overflow-hidden rounded-2xl section-shadow min-h-[520px] ${
        className || ""
      }`}
    >
      {MyJobList && MyJobList?.result_list && (
        <div className="relative w-full overflow-x-auto sm:rounded-lg flex flex-col justify-between h-full">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <tbody>
              {
                <>
                  {MyJobList &&
                  MyJobList?.result_list &&
                  MyJobList.result_list.length > 0 ? (
                    currentActiveJobList.map((value, index) => {
                      // let deliveryDate = value?.expire?.split(" ")[0];
                      let thePrice = PriceFormatter(
                        value?.price * 0.01,
                        value?.currency_code,
                        value?.currency
                      );
                      let image = `${MyJobList.session_image_server}${localStorage.getItem('session_token')}/job/${value.job_uid}`
                      return (
                        <tr
                          key={index}
                          className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50"
                        >
                          <td className=" py-4">
                            <div className="flex space-x-2 items-center w-full">
                              <div className="max-w-[60px] max-h-[60px] min-w-[60px] min-h-[60px] p-2 bg-alice-blue rounded-full overflow-hidden flex justify-center items-center">
                                <img
                                  src={image}
                                  alt="data"
                                  className="w-full h-full rounded-full"
                                />
                              </div>
                              <div className="flex flex-col flex-[0.9]">
                                <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                                  {value.title}
                                </h1>
                                <div>{value.description}</div>
                                <span className="text-sm text-thin-light-gray flex items-start gap-1">
                                Reward:{" "}
                                  <span className="text-purple">
                                    {thePrice}
                                  </span>
                                </span>
                                <div className="flex items-center gap-4">
                                  <span className="text-sm text-thin-light-gray">
                                    Duration:{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {value?.timeline_days} day(s)
                                    </span>
                                  </span>
                                  <span className="text-sm text-thin-light-gray">
                                    Expire:{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {value?.expire}
                                    </span>
                                  </span>
                                  <span className="text-sm text-thin-light-gray">
                                    Sent :{" "}
                                    <span className="text-purple">
                                      {" "}
                                      {value?.sent}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className="text-right py-4 px-2">
                            <button
                              type="button"
                              onClick={() => {
                                setJobPopout({ show: true, data: value });
                              }}
                              className="w-20 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                      <td className="p-2">No Pending Task!</td>
                    </tr>
                  )}
                </>
              }
            </tbody>
          </table>
          {/* PAGINATION BUTTON */}
          <PaginatedList
            onClick={handlePagination}
            prev={currentPage == 0 ? true : false}
            next={
              currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
              MyJobList?.result_list.length
                ? true
                : false
            }
            data={MyJobList?.result_list}
            start={indexOfFirstItem}
            stop={indexOfLastItem}
          />
          {/* END OF PAGINATION BUTTON */}
        </div>
      )}

      {/* Active Job Popout */}
      {jobPopout.show && (
        <MarketPopUp
          details={jobPopout.data}
          onClose={() => {
            setJobPopout({ show: false, data: {} });
          }}
          situation={jobPopout.show}
        />
      )}
      {/* End of Active Job Popout */}
    </div>
  );
}
