import React, { useEffect, useState } from "react";
// import authProfilePic from "../../assets/images/auth-profile-picture.png";
// import profileBanner from "../../assets/images/profile-cover.png";
// import collections from "../../data/collectionplan_data.json"; Should this be cleaned off?
// import marketPlace from "../../data/marketplace_data.json";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner";
import products from "../../data/product_data.json";
import usersService from "../../services/UsersService";
import Layout from "../Partials/Layout";
import {
  ActivitiesTab,
  BlogTab,
  CollectionTab,
  HiddenProductsTab,
  MyUploadedFiles,
  OwnTab,
  QuestionsTab,
} from "./tabs";
//import MyUploadedFiles from "./tabs/MyUploadedFiles";

export default function Resources(props) {
  // console.log("RESOURCES=>", props);
  const ownProducts = products.datas;
  // Resource Props
  const __resources = props.MyResourceData;

  //debugger;
  // Collection Items
  const CreatedSell = __resources?.marketdata?.data;
  // const tab_categories = __resources?.tab_categories?.data?.sort((a, b) => b.name.localeCompare(a.name)) // SORT REMOVED
  const tab_categories = __resources?.tab_categories?.data
  const collectionProducts = __resources?.collectiondata?.data;
  const onSaleProducts = __resources?.marketdata?.data;
  const CreatedBits = __resources?.productdata?.datas;
  const blogItems = __resources?.blogdata?.payload;

  console.log(__resources);
  console.log("Blog data >> ", blogItems);

  // My Items Data
  const [uploadedFiles, setUploadedFiles] = useState({
    loading: false,
    msg: "",
    data: null,
  });
  const { uploadsTable } = useSelector((state) => state.tableReload);

  const apiCall = new usersService();

  const getUploadedFiles = async () => {
    setUploadedFiles((prev) => ({ ...prev, loading: true }));

    let res;

    try {
      res = await apiCall.getUploadedList();
      res = res.data;
      if (res?.internal_return < 0) {
        setUploadedFiles((prev) => ({
          ...prev,
          loading: false,
          msg: "something went wrong",
        }));
      }
      setUploadedFiles((prev) => ({
        ...prev,
        loading: false,
        msg: "success",
        data: res?.result_list,
        image: res?.session_image_server,
      }));
    } catch (error) {
      setUploadedFiles((prev) => ({
        ...prev,
        loading: false,
        msg: "An error occurred",
      }));
    }
  };

  // const [tab, setTab] = useState(tab_categories ? tab_categories[0]?.name : "");
  const [tab, setTab] = useState(
    props.activeTab
      ? props.activeTab
      : "onsale"
  );

  const tabHandler = (value) => {
    setTab(value);
  };

  useEffect(() => {
    getUploadedFiles();
  }, [uploadsTable]);


  // Category Components
  const tabComponents = {
    blog: <BlogTab blogdata={blogItems} />,
    onsale: <QuestionsTab products={onSaleProducts} />,
    owned: <OwnTab products={ownProducts} />,
    created: <MyUploadedFiles uploadedFiles={uploadedFiles} />,
    hidden: (
      <HiddenProductsTab
        marketProducts={CreatedSell}
        mainProducts={CreatedBits}
      />
    ),
    collection: <CollectionTab products={collectionProducts} />,
    activity: <ActivitiesTab />,
  };

  // const defaultTabComponent = <BlogTab blogdata={blogItems} />;

  // const selectedTabComponent = tabComponents[tab] || defaultTabComponent;

  const defaultTabComponent = props.activeTab ? (
    tabComponents[props.activeTab]
  ) : (
    <BlogTab blogdata={blogItems} />
  );
  const selectedTabComponent = tabComponents[tab] || defaultTabComponent;

  // Tab Item Component
  const TabItem = ({ tabValue, isActive }) => {
    let countNumber = (name) => {
      // FUNCTION TO DETERMINE THE COUNT NUMBER
      if (name == "blog") {
        return blogItems?.blogdata?.length;
      } else if (name == "onsale") {
        return onSaleProducts?.length;
        // return null
      } else if (name == "owned") {
        return ownProducts?.length;
      } else if (name == "created") {
        return uploadedFiles?.data?.length;
      } else {
        return 0;
      }
    };
    return (
      <li
        className={`relative group inline`}
        onClick={() => tabHandler(tabValue.name)}
      >
        <span
          className={`py-4 sm:border-b-none border-b group-hover:border-purple border-transparent lg:text-xl text-sm tracking-wide font-bold group-hover:text-purple text-dark-gray dark:text-white relative z-10 cursor-pointer ${
            isActive
              ? "text-purple border-purple"
              : "text-dark-gray dark:text-white border-transparent"
          }`}
        >
          {tabValue.content}
        </span>
        {tabValue.name != "onsale" && (
          <span
            className={`w-5 h-5 group-hover:bg-pink group-hover:text-white text-[10px] rounded-full absolute -top-2 -right-5 flex justify-center items-center ${
              isActive
                ? "text-white bg-pink"
                : "text-thin-light-gray bg-[#F2B8FD]"
            }`}
          >
            {countNumber(tabValue.name)}
          </span>
        )}
      </li>
    );
  };

  // Tab List Component
  const TabList = ({ tabCategories }) => {
    return (
      <ul className="lg:flex lg:space-x-14 space-x-8">
        {tabCategories?.length > 0 &&
          tabCategories?.map((tabValue, idx) => {
            if (tabValue.enabled) {
              return (
                <TabItem
                  key={tabValue.id}
                  tabValue={tabValue}
                  isActive={tab === tabValue.name || (idx === 0 && tab === "")}
                />
              );
            }
          })}
      </ul>
    );
  };

  return (
    <>
      <Layout>
        <div className="nft-authprofile-wrapper w-full">
          {__resources.length == 0 || Object.keys(__resources).length == 0 ? (
            <div className="w-full h-full flex items-center justify-center">
              <LoadingSpinner size={16} color="sky-blue" />
            </div>
          ) : (
            <div className="main-wrapper w-full">
              <div className="content-wrapper-profile-only w-full mb-6">
                <div className="auth-tab-content relative mb-10">
                  <div className="lg:flex justify-between">
                    <div className="tab-items">
                      <TabList tabCategories={tab_categories} />
                    </div>
                  </div>
                  <div className="hidden lg:block w-full h-[1px] bg-[#DCD5FE] dark:bg-[#5356fb29] absolute top-[42px] left-0"></div>
                </div>
              </div>

              <div className="tab-cotainer w-full mb-10">
                {selectedTabComponent}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}
