import React, {useState, useEffect} from "react";
import HistoryAnalyticsCard from "../Cards/HistoryAnalyticsCard";
import SellHistoryMarketVisitorAnalytic from "../Home/SellHistoryMarketVisitorAnalytic";
import Layout from "../Partials/Layout";
import HistoryTable from "./HistoryTable";
import MarketHistorySection from "./MarketHistorySection";
import TopHxBox from "./TopHxBox";

import usersService from "../../services/UsersService";
import PurchasesTable from "../MyWallet/WalletComponent/PurchasesTable";
import RecentActivityTable from "../MyWallet/WalletComponent/RecentActivityTable";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import RewardsTable from "./RewardsTable";
import JobsCompleted from "./JobsCompleted";

import TabButton from "../customTabs/TabButton";

export default function History() {

    const apiCall = new usersService()

    const [selectedTab, setSelectedTab] = useState("purchases");
    // let [tab, setTab] = useState("purchases"); //STATE FOR SWITCHING BETWEEN TABS
    // const tabs = ["purchases", "recent activity", "rewards", 'jobs completed'] //STATE FOR SWITCHING BETWEEN TABS

    const tabs = [  //STATE FOR SWITCHING BETWEEN TABS
        {
            id: 1,
            title: "purchases",
            iconName: "history",
          },
          {
            id: 2,
            title: "recent activity",
            iconName: "history",
          },
          {
            id: 3,
            title: "rewards",
            iconName: "history",
          },
          {
            id: 4,
            title: "jobs completed",
            iconName: "history",
          },
    ]

    let [paymentHistory, setPaymentHistory] = useState({ // FOR PAYMENT HISTORY
        loading: true,
        data: [],
        error: false
    })

    let [purchaseHistory, setPurchaseHistory] = useState({ // FOR PURCHASE HISTORY
        loading: true,
        data: [],
        error: false
    })

    //FUNCTION TO GET PAYMENT HISTORY
    const getPaymentHistory = ()=>{
      apiCall.getPaymentHx().then((res)=>{
          if(res.data.internal_return < 0){ // success but no data
              setPaymentHistory(prev => ({...prev, loading: false}))
              return
          }
          setPaymentHistory(prev => ({...prev, loading: false, data: res.data.result_list}))
      }).catch((error)=>{
          setPaymentHistory(prev => ({...prev, loading: false, error: true}))
      })
  }

  //FUNCTION TO GET PURCHASE HISTORY
  const getPurchaseHistory = ()=>{
      apiCall.getPurchaseHx().then((res)=>{
          if(res.data.internal_return < 0){ // success but no data
              setPurchaseHistory(prev => ({...prev, loading: false}))
              return
          }
          // console.log('purchase',res.data)
          setPurchaseHistory(prev => ({...prev, loading: false, data: res.data.result_list}))
      }).catch((error)=>{
          setPurchaseHistory(prev => ({...prev, loading: false, error: true}))
      })
  }

  useEffect(()=>{
    getPaymentHistory()
  }, [])

  useEffect(()=>{
    getPurchaseHistory()
  }, [])

  return (
    <>
      <Layout>
        <div className="history-wrapper w-full mb-10">
            <div className="w-full">
                <div className="sm:flex justify-between items-center mb-6">
                    <div className="mb-5 sm:mb-0">
                        <h1 className="text-26 font-bold   text-dark-gray dark:text-white">

                    History

                        </h1>
                    </div>
                    <div className="slider-btns flex space-x-4">

                    </div>
                </div>
                <div className="w-full h-full p-4 bg-white dark:bg-dark-white rounded-2xl section-shadow lg:flex lg:px-10 px-4 justify-between">
                    <div className="content-tab-items lg:w-[230px] w-full mr-2">
                        <div className='overflow-hidden mb-5 lg:mb-0 py-2 lg:py-8'>
                            {tabs.map((item) => (
                                <div key={item.id} className='w-full'>
                                    <TabButton 
                                        key={item.id}
                                        item={item.title}
                                        iconName={item.iconName}
                                        selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
                    <div className="w-full overflow-y-auto min-h-[520px]">
                        <>
                            {/* PURCHASE SECTION */}
                            {selectedTab == 'purchases' &&
                                <div className="wallet w-full">
                                    {/* <h1 className="p-2 text-xl font-bold text-dark-gray dark:text-white tracking-wide">Purchases</h1> */}
                                    {purchaseHistory.loading ? 
                                    <LoadingSpinner size='16' color='sky-blue' height='h-[500px]' />
                                    :
                                    <PurchasesTable purchase={purchaseHistory} />
                                    }
                                </div>
                            }
                            {/* END OF PURCHASE SECTION */}
                            
                            {/* RECENT ACTIVITY SECTION */}
                            {selectedTab == 'recent activity' &&
                                <div className="wallet w-full">
                                    {/* <h1 className="p-2 text-xl font-bold text-dark-gray dark:text-white tracking-wide">Recent Activity</h1> */}
                                    {/* <p className='text-base text-slate-500 dark:text-white'>Activity Report</p> */}
                                    {paymentHistory.loading ? 
                                    <LoadingSpinner size='16' color='sky-blue' height='h-[500px]' />
                                    :
                                    <RecentActivityTable payment={paymentHistory} />
                                }
                                </div>
                            }
                            {/* END OF RECENT ACTIVITY SECTION */}

                            {/* REWARD SECTION */}
                            {selectedTab == 'rewards' &&
                                <div className="wallet w-full">
                                    <RewardsTable />
                                </div>
                            }
                            {/* END OF REWARD SECTION */}

                            {/* JOBS COMPLETED SECTION */}
                            {selectedTab == 'jobs completed' &&
                                <div className="wallet w-full">
                                    <JobsCompleted />
                                </div>
                            }
                            {/* END OF JOBS COMPLETED SECTION */}
                        </>
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    </>
  );
}
