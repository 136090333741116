import { useSelector } from "react-redux";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import WalletItemCard from "./WalletItemCard";
import WalletItemCardVirtual from './walletvirtual/WalletItemCardVirtual'

/**
 * Renders a list of wallet items or a loading spinner depending on the state of the `wallet` object.
 */
export default function WalletBox({ wallet, payment, countries }) {
  const { loading, data } = wallet;

  // console.log('LOG', data[0]?.extra_actions?.SPEND_FROM_BALANCE[0])

  // const { userDetails } = useSelector((state) => state.userDetails);
  // const accountType = userDetails?.account_type === "FAMILY";

  return (
    <div className="my-wallet-wrapper w-full mb-10">
      <div className="main-wrapper w-full">
      {/* xl:grid-cols[repeat(auto-fill,_minmax(354px,_1fr))] min-[1440px]:grid-cols-[repeat(auto-fill,_minmax(415px,_1fr))] */}
          {loading ? (
            <div className="w-full h-full flex items-center justify-center">
              <LoadingSpinner size="16" color="sky-blue" />
            </div>
          ) : (
            <div className="w-auto grid md:grid-cols-2 xxl:grid-cols-3 gap-4 md:gap-10">
            { data?.length > 0 && data.map((item, index) => (
                <div key={item.wallet_uid+index} className="w-full h-full">
                  {item.country ?
                  <WalletItemCard walletItem={item} payment={payment} countries={countries} />
                  :
                  <WalletItemCardVirtual walletItem={item} payment={payment} countries={countries} />
                  }
                </div>
              ))}
          </div>
          )}
      </div>
    </div>
  );
}
