import { useSelector } from "react-redux";
import {
  AccountDashboard,
  FamilyParentDashboard,
  HomeDashboard,
  JobOwnerDashboard,
  WorkerDashboard,
} from "../Dashboards";
import MyJobTable from "../MyTasks/MyJobTable";
import MyOffersTable from "../MyTasks/MyOffersTable";

export default function FullAccountDash(props) {
  // console.log("PROPS IN HOME->", props);

  const { userDetails } = useSelector((state) => state?.userDetails);

  const renderDashboard = () => {
    switch (props.dashTypes) {
      case "DEFAULT_HOME_DASH":
        return (
          <HomeDashboard
            className="mb-4"
            data={userDetails}
            bannerList={props.bannerList}
          />
        );
      case "FAMILY_PARENT_DASH":
        return (
          <FamilyParentDashboard
            className="mb-4"
            data={userDetails}
            bannerList={props.bannerList}
          />
        );
      case "WORKER_HOME_DASH":
        return (
          <WorkerDashboard
            className="mb-4"
            data={userDetails}
            bannerList={props.bannerList}
          />
        );
      case "JOBOWNER_HOME_DASH":
        return (
          <JobOwnerDashboard
            className="mb-4"
            data={userDetails}
            bannerList={props.bannerList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="home-page-wrapper">
        {renderDashboard()}
        {process.env.REACT_APP_SHOW_ACCOUNT_DASH == "1" && (
          <AccountDashboard className="mb-4" bannerList={props.bannerList} offersList={props.offersList} imageServer={props.imageServer} />
        )}

        {/* {props?.dashTypes !== "undefined" &&
        props.offersList?.data?.result_list?.length ? (
          <MyOffersTable
            MyActiveOffersList={props.offersList?.data}
            className="mb-10"
          />
        ) 
        : 
        props.MyActiveJobList?.data?.length ? (
          <>
            <div className="w-full mb-5 flex justify-between items-center gap-1">
              <h1 className="text-26 font-bold   text-dark-gray dark:text-white">
                <span>My Tasks</span>
              </h1>
            </div>
            <MyJobTable
              ActiveJobList={props.MyActiveJobList}
              Account={userDetails}
              imageServer={props.offersList?.data?.session_image_server}
            />
          </>
        ) : null} */}
      </div>
    </>
  );
}

// : !props.offersList?.loading && !props.MyActiveJobList?.loading ? (
//   <HomeActivities className="mb-10" />
// ) 
// : (
//   <div className="w-full h-[220px] flex items-center justify-center">
//     <LoadingSpinner size="16" color="sky-blue" />
//   </div>
// )
