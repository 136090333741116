import React, {useState, useEffect} from "react";
import Layout from "../Partials/Layout";

import usersService from "../../services/UsersService";
import LoadingSpinner from "../Spinners/LoadingSpinner";

import TabButton from "../customTabs/TabButton";

export default function LearnMore() {

    const apiCall = new usersService()

    const [selectedTab, setSelectedTab] = useState("topic 1");

    const [tabs, setTabs] = useState(
        [  //STATE FOR SWITCHING BETWEEN TABS
            {
                id: 1,
                title: "topic 1",
                iconName: "history",
              },
              {
                id: 2,
                title: "topic 2",
                iconName: "history",
              },
              {
                id: 3,
                title: "topic 3",
                iconName: "history",
              },
              {
                id: 4,
                title: "topic 4",
                iconName: "history",
              },
        ]
    )

    // const tabs = [  //STATE FOR SWITCHING BETWEEN TABS
    //     {
    //         id: 1,
    //         title: "topic 1",
    //         iconName: "history",
    //       },
    //       {
    //         id: 2,
    //         title: "topic 2",
    //         iconName: "history",
    //       },
    //       {
    //         id: 3,
    //         title: "topic 3",
    //         iconName: "history",
    //       },
    //       {
    //         id: 4,
    //         title: "topic 4",
    //         iconName: "history",
    //       },
    // ]

    let [topics, setTopics] = useState({ // FOR PAYMENT HISTORY
        loading: true,
        data: [],
    })

    //FUNCTION TO GET LEARN MORE TOPIC
  
  useEffect(()=>{
    apiCall.getLearnmoreTopics().then((res)=>{
        if(res.data.internal_return < 0){ // success but no data
            setTopics(prev => ({...prev, loading: false}))
            return
        }
        const resData = res?.data?.result_list
        setTopics(prev => ({...prev, loading: false, data: resData}))
        setTabs(prev => {
           return prev.map((item, index) => ({...item, title:resData[index].topic}))
        })
        setSelectedTab(resData[0].topic)
        // console.log('RES', resData)
    }).catch((error)=>{
        setTopics(prev => ({...prev, loading: false}))
    })
  }, [])


  return (
    <>
      <Layout>
        <div className="history-wrapper w-full mb-10">
            <div className="w-full">
                <div className="sm:flex justify-between items-center mb-6">
                    <div className="mb-5 sm:mb-0">
                        <h1 className="text-26 font-bold   text-dark-gray dark:text-white">

                    Learn More

                        </h1>
                    </div>
                    <div className="slider-btns flex space-x-4">

                    </div>
                </div>
                <div className="w-full h-full p-4 bg-white dark:bg-dark-white rounded-2xl section-shadow lg:flex lg:px-10 px-4 justify-between">
                    <div className="content-tab-items lg:w-[230px] w-full mr-2">
                        <div className='overflow-hidden mb-5 lg:mb-0 py-2 lg:py-8'>
                            {(!topics.loading  && topics?.data?.length > 0) && tabs.map((item) => (
                                <div key={item.id} className='w-full'>
                                    <TabButton 
                                        key={item.id}
                                        item={item.title}
                                        iconName={item.iconName}
                                        selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-[1px] bg-[#E3E4FE] dark:bg-[#a7a9b533]  mr-10"></div>
                    <div className="flex-1 overflow-y-auto min-h-[520px]">
                        <>
                            {/* TOPICS SECTION */}
                            <div className="w-full py-2 lg:py-8">
                                {topics.loading ? 
                                <LoadingSpinner size='16' color='sky-blue' height='h-[500px]' />
                                :
                                <>
                                    {(topics?.data && topics?.data?.length > 0) ?
                                    <div dangerouslySetInnerHTML={{
                                        __html: topics?.data?.filter(item => item.topic == selectedTab)[0]?.contents,
                                      }} className='prose dark:text-white dark:bg-dark-white'
                                    >
                                        {/* {topics?.data?.filter(item => item.topic == selectedTab)[0]?.contents} */}
                                    </div>
                                    :
                                    <p className='p-2 text-slate-500 flex flex-col grow justify-center items-center'>No Topics found</p>}
                                </>
                                }
                            </div>
                            {/* END OF TOPICS SECTION */}
                        </>
                    </div>
                </div>
            </div>
        </div>
      </Layout>
    </>
  );
}
