import React, { useEffect, useState } from "react";
import usersService from "../../../services/UsersService";


function WalletActionVirtual({ walletItem, openPopUp, btnText }) {

  return (
    <div className="counters w-full flex justify-end gap-2">
      <div className="w-1/2 flex justify-end items-center">
        <button
          className="logout-btn btn-gradient text-white"
          onClick={() => {
            openPopUp(walletItem?.description, {walletItem});
          }}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
}

export default WalletActionVirtual;
