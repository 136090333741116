import ProductCardStyleTwo from "../../Cards/ProductCardStyleTwo";
import DataIteration from "../../Helpers/DataIteration";
import SearchCom from "../../Helpers/SearchCom";
import ResourceBlogCard from "../../Cards/ResourceBlogCard";
import NewPaginatedList from '../../../components/Pagination/NewPaginatedList'

export default function BlogTab({ className, blogdata }) {
  // console.log("Blog data here>> ", blogdata);
  // debugger;
  return (
    <>
      <div className={`onsale-tab-wrapper w-full ${className || ""}`}>
        <div className="main-container w-full">
          <div className="filter-section w-full items-center sm:flex justify-between mb-6">
            {/* filter-search */}
            <div className="sm:w-1/2 w-full sm:pr-20 pr-0 mb-5 sm:mb-0">
              <SearchCom placeholder='Search Blog Items...' />
            </div>
          </div>

          {/* <div className="content-section w-full-width">
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
              <DataIteration
                datas={blogdata?.blogdata}
                startLength={process.env.REACT_APP_ZERO_STATE}
                endLength={blogdata?.blogdata.length}
              >
                {({ datas }) => (
                  <ResourceBlogCard
                    key={datas.id}
                    datas={datas}
                    bg={blogdata.image_url}
                  />
                )}
              </DataIteration>
            </div>
          </div> */}

          {blogdata?.blogdata?.length ?
          <NewPaginatedList
              data={blogdata?.blogdata}
              itemsPerPage={9}
              filterItem=''
              tableTitle='' 
          >
            {
              ({data})=>(
                <div className="filter-navigate-content w-full min-h-[600px]">
                  <div
                    className={"grid lg:grid-cols-3 sm:grid-cols-2 gap-[30px]"}
                  >
                  {
                    data.map((datum, index) => (
                      <div key={index}>
                        <ResourceBlogCard
                          key={index}
                          datas={datum}
                          bg={blogdata.image_url}
                        />
                      </div>
                    ))
                  }
                  </div>
                </div>
              )
            }
          </NewPaginatedList>
          :
          <div className="w-full h-[40rem] bg-white dark:bg-dark-white flex justify-center items-center">
            No Blog Found!
          </div>
          }
        </div>
      </div>
    </>
  );
}
