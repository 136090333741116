import { useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import PaginatedList from "../Pagination/PaginatedList";
import { handlePagingFunc } from "../Pagination/HandlePagination";
import LoadingSpinner from "../Spinners/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { PriceFormatter } from "../Helpers/PriceFormatter";
import localImgLoad from "../../lib/localImgLoad";

const noTasksBg = require("../../assets/images/no-task-background.jpg");
const noFamilyTasksBg = require("../../assets/images/family-no-task-background.jpg");

export default function RecentPastDueTable({ className, recentPastDue, Account, imageServer }) {
  let navigate = useNavigate();
  let { pathname } = useLocation();

  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentTask = recentPastDue?.data?.result_list?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  let accountType = Account?.account_type == "FULL"

  // To navigate to task
  const [btnLoader, setBtnLoader] = useState(false);
  const navigateMarket = () => {
    setBtnLoader(true);
    const timeoutId = setTimeout(() => {
      navigate(accountType ? "/market" : "/familymarket", { replace: true });
      setBtnLoader(false);
    }, 2500);
    return () => clearTimeout(timeoutId);
  };

  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white overflow-hidden rounded-2xl section-shadow min-h-[520px] relative ${
        className || ""
      } bg-cover bg-center`}
      style={{
        backgroundImage: !recentPastDue?.data?.result_list?.length
          ? `url('${accountType ? noTasksBg : noFamilyTasksBg}')`
          : "none",
      }}
    >
      {/* Adding this dark overlay in order to see the texts properly */}
      {!recentPastDue?.data?.result_list?.length && accountType && (
        <div className="absolute inset-0 bg-black opacity-30"></div>
      )}
      {recentPastDue?.loading ? 
        <div className="w-full h-[520px] flex items-center justify-center">
          <LoadingSpinner size="16" color="sky-blue" />
        </div>
      :
      <div className="relative w-full sm:rounded-lg">
        <div className="h-auto w-full">
          {recentPastDue?.data?.result_list?.length > 0 ?
            currentTask?.map((task, idx) => {
              // find due date
              const dueDate = task?.delivery_date.split(" ")[0];
              let thePrice = PriceFormatter(
                task?.price * 0.01,
                task?.currency_code,
                task?.currency
              );
              let image = `${imageServer}${localStorage.getItem('session_token')}/job/${task.origin_job_uid}`
              return (
                <div
                  className="bg-white dark:bg-dark-white border-b dark:border-[#5356fb29] w-full flex justify-between items-center hover:bg-gray-50"
                  key={idx}
                >
                  <div className="py-4 max-w-[80%]">
                    <div className="flex space-x-2 items-center">
                      <div className="w-full min-w-[60px] max-w-[60px] flex-[0.1] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                        <img
                          src={image}
                          alt="data"
                          className="w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col flex-[0.9]">
                        <h1 className="font-bold text-xl text-dark-gray dark:text-white">
                          {task?.title}
                        </h1>
                        <span className="text-base text-gray-600">
                          {task?.description}
                        </span>
                        <span className="text-sm text-thin-light-gray flex flext-start gap-1">
                          Reward:
                          <span className="text-purple ml-1">{thePrice}</span>
                        </span>
                        <div className="flex flex-col sm:flex-row items-start gap-1 md:gap-4 md:items-center">
                          <span className="text-sm text-thin-light-gray">
                            Duration:
                            <span className="text-purple ml-1">
                              {Number(task?.timeline_days) === 1
                                ? `${task?.timeline_days} day`
                                : `${task?.timeline_days} day(s)`}
                            </span>
                          </span>
                          <span className="text-sm text-thin-light-gray">
                            Due Date:
                            <span className="text-purple ml-1">{dueDate}</span>
                          </span>
                          <span className="text-sm text-thin-light-gray">
                            Confirmation:
                            <span className="text-purple ml-1">
                              {task?.contract}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center py-4 px-2">
                    {accountType ?
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/manage-active-job", {
                            state: { ...task, pathname },
                          });
                        }}
                        className="px-4 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                      >
                        {task.owner_status == 'OWNER' ? 'Manage' : 'Send Updates'}
                      </button>
                      :
                      <button
                        type="button"
                        onClick={() => {
                          navigate("/manage-active-job", {
                            state: { ...task, pathname },
                          });
                        }}
                        className="w-12 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 11 20"
                          id="Arrow"
                          className="w-[0.7rem]"
                        >
                          <path
                            fillRule="evenodd"
                            d="M.366 19.708c.405.39 1.06.39 1.464 0l8.563-8.264a1.95 1.95 0 0 0 0-2.827L1.768.292A1.063 1.063 0 0 0 .314.282a.976.976 0 0 0-.011 1.425l7.894 7.617a.975.975 0 0 1 0 1.414L.366 18.295a.974.974 0 0 0 0 1.413"
                            // fill=""
                            className="color000000 svgShape fill-[#fff]"
                          ></path>
                        </svg>
                      </button>
                    }
                  </div>
                </div>
              );
            })
          :
            <div
              className={`flex flex-col ${
                accountType ? "items-center" : "items-end"
              } justify-center gap-9 my-5`}
            >
              <div
                className={`p-2 font-bold text-3xl ${
                  accountType ? "text-white" : "text-sky-blue"
                } whitespace-nowrap`}
              >
                You currently have {accountType ? '"0"' : "no"} Past due task
              </div>
              <div
                className={`
                  ${!accountType &&
                  "bg-[#f0f8ff] h-[17rem] p-5 rounded-md -translate-x-20"}
                `}
              >
                <button
                  className={`${
                    !accountType
                      ? "w-[150px] h-20 bg-[#22b14c] text-lg font-semibold rounded-md py-2 px-4"
                      : "w-[115px] h-11 btn-gradient rounded-full"
                  } flex justify-center items-center text-base text-white`}
                  onClick={navigateMarket}
                >
                  {btnLoader ? (
                    <div className="signup btn-loader"></div>
                  ) : accountType ? (
                    "Find Task"
                  ) : (
                    "Suggest tasks to your parent"
                  )}
                </button>
              </div>
            </div>
          }
        </div>

        {/* PAGINATION BUTTON */}
        <PaginatedList
          onClick={handlePagination}
          prev={currentPage == 0 ? true : false}
          next={
            currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
            recentPastDue?.data?.result_list?.length
              ? true
              : false
          }
          data={recentPastDue?.data?.result_list}
          start={indexOfFirstItem}
          stop={indexOfLastItem}
        />
        {/* END OF PAGINATION BUTTON */}
      </div>
      }
    </div>
  );
}
