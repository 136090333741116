import React, { useState, useEffect } from "react";
import datas from "../../data/product_data.json";
import Layout from "../Partials/Layout";
import usersService from "../../services/UsersService";
import { useSelector } from "react-redux";
import FamilyDash from "./FamilyDash";
import FullAccountDash from "./FullAccountDash";
import LoadingSpinner from '../../components/Spinners/LoadingSpinner'

export default function Home(props) {
 // console.log("PROPS IN HOME->", props);
  const userApi = new usersService();
  const { commonHeadBanner } = useSelector((state) => state.commonHeadBanner);

  const { userDetails } = useSelector((state) => state?.userDetails);

  // const [MyActiveJobList, setMyActiveJobList] = useState({loading:true, data:[]}); // STATE TO HOLD ACTIVE/CURRENT TASKS

  // const getMyActiveJobList = async () => { // FUNCTION TO POPULATE ACTIVE/CURRENT TASK LIST
  //   try {
  //     const res = await userApi.getMyActiveTaskList();
  //     setMyActiveJobList({loading:false, data:res?.data?.result_list});
  //     // setMyActiveJobList(res?.data?.result_list);
  //   } catch (error) {
  //     setMyActiveJobList({loading:false, data:[]});
  //     // setMyActiveJobList([]);
  //     console.log("Error getting tasks");
  //   }
  // };

  // FUNCTION TO GET DASH DATA TO DETERMINE CURRENT TASK DUE TIME
  // const getHomeDate = () => {
  //   userApi
  //     .getHomeDate()
  //     .then((res) => {
  //       if (res.status != 200 || res.internal_return < 0) {
  //         return;
  //       }
  //       setNextDueTask(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const getMyOffersList = async () => {
  //   try {
  //     const res = await userApi.getOffersList();
  //     setMyOffersList({loading:false, data:res.data});
  //   } catch (error) {
  //     setMyOffersList({loading:false, data:[]});
  //     console.log("Error getting offers", error);
  //   }
  // };

  // useEffect(() => {
  //   if(userDetails?.account_type == 'FULL'){
  //     getMyActiveJobList();
  //   }
  // }, []);

  return (
    <Layout>
      {/* {Object.keys(commonHeadBanner).length < 1 ?
      <LoadingSpinner height='h-48' size='16' />
      :
      <div className="w-full">
        {userDetails && userDetails?.account_type == "FAMILY" ? (
          <FamilyDash
            account={userDetails}
            commonHeadData={props.bannerList}
            // familyOffers={MyOffersList?.data}
            serverImg = {userDetails?.session_image_server}
            // MyActiveJobList={MyActiveJobList?.data}
          />
        ) : userDetails && userDetails?.account_type == "FULL" ? (
          <FullAccountDash
            bannerList={props.bannerList}
            dashTypes={props.dashTypes}
            // offersList={MyOffersList}
            // MyActiveJobList={MyActiveJobList}
            offersList={props.offersList}
            imageServer={props.imageServer}
          />
        ) : (
          <div>
            You are not logged in or your account type is not supported.
          </div>
        )}
      </div>
      } */}

        <div className="w-full">
          {userDetails && userDetails?.account_type == "FAMILY" ? (
            <FamilyDash
              account={userDetails}
              commonHeadData={props.bannerList}
              // familyOffers={MyOffersList?.data}
              serverImg = {userDetails?.session_image_server}
              // MyActiveJobList={MyActiveJobList?.data}
            />
          ) : userDetails && userDetails?.account_type == "FULL" ? (
            <>
              {Object.keys(commonHeadBanner).length < 1 ?
              <LoadingSpinner height='h-48' size='16' />
              :
              <FullAccountDash
                bannerList={props.bannerList}
                dashTypes={props.dashTypes}
                // offersList={MyOffersList}
                // MyActiveJobList={MyActiveJobList}
                offersList={props.offersList}
                imageServer={props.imageServer}
              />
              }
            </>
          ) : (
            <div>
              You are not logged in or your account type is not supported.
            </div>
          )}
        </div>
    </Layout>
  );
}
