import { useState } from "react";
import method1 from "../../../assets/images/icons/bank.svg";
import localImgLoad from "../../../lib/localImgLoad";
import { PaginatedList, handlePagingFunc } from "../../Pagination";
import LoadingSpinner from "../../Spinners/LoadingSpinner";

export default function RecipientAccountTab({
  recipientAccount,
  setRecipientAccount,
}) {
  const cardList = recipientAccount?.data?.result_list;
  const handleDeleteCardModal = () => {
    setRecipientAccount((prev) => ({ ...prev, state: !prev.state }));
  };

  const [currentPage, setCurrentPage] = useState(0);
  const indexOfFirstItem = Number(currentPage);
  const indexOfLastItem =
    Number(indexOfFirstItem) + Number(process.env.REACT_APP_ITEM_PER_PAGE);
  const currentCardList = cardList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePagination = (e) => {
    handlePagingFunc(e, setCurrentPage);
  };

  return (
    <>
      <div className="payment-method-tab w-full">
        <div className="payment-item-wrapper w-full">
          <ul className="payment-items w-full min-h-[400px]">
            {recipientAccount?.loading ? (
              <div className="w-full h-[300px] flex justify-center items-center">
                <LoadingSpinner size="8" color="sky-blue" />
              </div>
            ) : cardList?.length ? (
              currentCardList.map((item) => {
                let image =
                  item.description &&
                  localImgLoad(
                    `images/settings/${item.description.toLowerCase()}.svg`
                  );
                let addedDate = item?.added?.split(" ")[0];

                return (
                  <li
                    key={item.recipient_id}
                    className="sm:flex justify-between items-center w-full py-3 border-b border-light-purple dark:border-[#5356fb29] "
                  >
                    <div className="flex space-x-5 mb-2 sm:mb-0">
                      <div className="w-[70px] h-[70px] flex items-center justify-center rounded-full sm:bg-light-purple dark:bg-dark-light-purple ">
                        <img
                          src={image ? image : method1}
                          className="w-[50px] h-[50px]"
                          alt="payment"
                        />
                      </div>
                      <div className="flex flex-col justify-around">
                        <p className="sm:text-xl text-lg tracking-wide   text-dark-gray dark:text-white">
                          Added: {addedDate}
                        </p>
                        <p className="text-thin-light-gray sm:text-18 text-base tracking-wide">
                          {item?.recipient}
                        </p>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          // setRecipientAccount({ state: true, data: item });
                          console.log("Testing...")
                        }}
                        className="w-[95px] sm:h-[46px] h-[40px] rounded-full btn-gradient text-white sm:text-18 text-md tracking-wide"
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                );
              })
            ) : (
              <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                No Recipient on this account yet.
              </h1>
            )}
          </ul>

          {/* PAGINATION BUTTON */}
          <PaginatedList
            onClick={handlePagination}
            prev={currentPage == 0 ? true : false}
            next={
              currentPage + Number(process.env.REACT_APP_ITEM_PER_PAGE) >=
              cardList?.length
                ? true
                : false
            }
            data={cardList}
            start={indexOfFirstItem}
            stop={indexOfLastItem}
          />
          {/* END OF PAGINATION BUTTON */}
        </div>
      </div>
    </>
  );
}
