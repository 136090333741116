import React from "react";
// import HomeSliders from "./HomeSliders";
import { useSelector } from "react-redux";

export default function FamilyParentDashboard({
  className,
  bannerList,
}) {
  const { userDetails } = useSelector((state) => state?.userDetails);

  let loginDate = userDetails?.last_login.split(" ")[0];
  let { firstname, lastname, email } = userDetails;
  let userEmail = email.split("@")[0];

  return (
    <div
      className={`w-full md:h-[47px] xxs:h-[74px] flex px-[1.05rem] py-[0.35rem] flex-wrap justify-between items-center gap-2 rounded-2xl overflow-hidden bg-sky-blue ${
        className || ""
      }`}
      style={{
        // background: `url(${heroBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="h-full flex flex-wrap w-full justify-between mb-5 lg:mb-0">
        {/* heading */}
        <div className="flex gap-8">
          <h1 className="text-base font-medium text-white tracking-wide">
            Welcome
          </h1>
          {/* user */}
          <div className="flex flex-col gap-1">
            <p className="text-base tracking-wide font-bold antise text-white leading-[1]">
              {`${firstname} ${lastname}`}
            </p>
            <p className="text-sm tracking-wide text-white leading-[1]">
              @{userEmail}
            </p>
          </div>
        </div>
        <span className="text-base font-thin tracking-wide text-white flex items-end">
          Last Login : {loginDate}
        </span>
      </div>
    </div>
  );
}
