import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import usersService from "../../services/UsersService";
import { tableReload } from "../../store/TableReloads";
import InputCom from "../Helpers/Inputs/InputCom";
import LoadingSpinner from "../Spinners/LoadingSpinner";

// Initialize state for request values
const initialReqState = {
  loading: false,
  status: false,
  message: "",
};

// For form initial values
const initialValues = {
  // initial values for formik
  country: "",
  price: "",
  title: "",
  description: "",
  job_detail: "",
  timeline_days: "",
  category: [],
};

// const getWalletDetail = (country) => { // A FUNCTION TO GET USER BALANCE BASED ON COUNTRY SELECTED
//   const walletChecker = walletDetails?.data.find(
//     (item) => item.country === country
//   );
//   return walletChecker ? walletChecker.amount : 0;
// };

// To get the validation schema
const validationSchema = Yup.object().shape({
  country: Yup.string()
    .min(1, "Minimum 3 characters")
    .max(25, "Maximum 25 characters")
    .required("Currency is required"),
  price: Yup.string()
    .typeError("Invalid number")
    .min(1, "Price must be greater than 0")
    .test("no-e", "Invalid number", (value) => {
      if (value && /\d+e/.test(value)) {
        return false;
      }
      return true;
    })
    .required("Price is required"),
  title: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(80, "Maximum 80 characters")
    .required("Title is required"),
  description: Yup.string()
    .min(5, "Minimum 5 characters")
    .max(299, "Maximum 299 characters")
    .required("Description is required"),
  job_detail: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(499, "Maximum 499 characters")
    .required("Details is required"),
  timeline_days: Yup.number()
    .typeError("you must specify a number")
    .min(1, "Price must be greater than 0")
    .required("Timeline is required"),
  category: Yup.array().min(1, "Select category"),
});

const getWalletDetail = (countryParams, walletDetails) => {
  // A FUNCTION TO GET USER BALANCE BASED ON COUNTRY SELECTED
  const walletChecker = walletDetails?.data.find(
    (item) => item.country === countryParams
  );
  return walletChecker
    ? {
        description: walletChecker.description,
        country: walletChecker.country,
      }
    : "";
};

export {
  Field,
  Form,
  Formik,
  useState,
  useEffect,
  useDispatch,
  useSelector,
  usersService,
  InputCom,
  LoadingSpinner,
  initialReqState,
  initialValues,
  validationSchema,
  getWalletDetail,
  tableReload
};
